import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { RankEffectiveBetRecordType, RankCategoryType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import MultipleSelector, { PropTypes as MultipleSelectorPropTypes } from '../../default/form/MultipleSelector'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getTimeFromDateInputValue, getValueFromChangeEvent, guaranteeBeKey, parseArray, getValueFromValue, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth, setLastMonth } from '../../../utils/default/TimeHelper'
import allRoutes from '../route/route'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { createValidateNotEmpty, createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import rankEffectiveBetRecordTypeName from '../../../constants/admin/rankEffectiveBetRecordTypeName'
import rankCategoryTypeName from '../../../constants/admin/rankCategoryTypeName'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import PlayerRankSetButton from './PlayerRankSetButton'
import { useNavigate } from 'react-router'
import allRoute from '../../admin/route/route'

export interface PlayerRankEffectiveBetRequest {
  page: number
  account: string
  start_at: number
  end_at: number
  effective_bet_types: RankEffectiveBetRecordType[]
  rank_types: RankCategoryType[]
  effective_bet_operator: string
  rank_operator: string
  effective_bet_memo: string
  rank_memo: string
  tab: number
}

export interface PlayerRankEffectiveBetFormType {
  account: string
  time: DateInputValue
  effectiveBetTypes: RankEffectiveBetRecordType[]
  rankTypes: RankCategoryType[]
  effectiveBetOperator: string
  rankOperator: string
  effectiveBetMemo: string
  rankMemo: string
}

export const searchToRequest: SearchToRequestFunc<PlayerRankEffectiveBetRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const convert = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    account: guaranteeNotUndefined(search.account),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    effective_bet_types: acceptUndefined(search.effective_bet_types, pipe(
      parseArray,
      (values) => values.map((value: string) => guaranteeBeKey(value, Object.keys(rankEffectiveBetRecordTypeName)))
    )),
    rank_types: acceptUndefined(search.rank_types, pipe(
      parseArray,
      (values) => values.map((value: string) => guaranteeBeKey(value, Object.keys(rankCategoryTypeName)))
    )),
    tab: pipe(
      guaranteeNotUndefined,
      parseInt
    )(search.tab)
  } as PlayerRankEffectiveBetRequest
  return omitBy(convert, isUndefined) as PlayerRankEffectiveBetRequest
}

export const initialForm: InitialFormFunc<PlayerRankEffectiveBetFormType> = (defaultForm) => ({
  account: '',
  time: {
    start: null,
    end: null
  },
  effectiveBetTypes: [],
  rankTypes: [],
  effectiveBetOperator: '',
  rankOperator: '',
  effectiveBetMemo: '',
  rankMemo: '',
  ...defaultForm
})

const formToRequest = (form: PlayerRankEffectiveBetFormType): PlayerRankEffectiveBetRequest => {
  const converted = {
    account: convertEmptyToUndefined(form.account),
    start_at: form.time.start ? getTime(form.time.start) : undefined,
    end_at: form.time.end ? getTime(form.time.end) : undefined,
    effective_bet_types: form.effectiveBetTypes.length > 0 ? form.effectiveBetTypes : undefined,
    rank_types: form.rankTypes.length > 0 ? form.rankTypes : undefined,
    effective_bet_operator: convertEmptyToUndefined(form.effectiveBetOperator),
    rank_operator: convertEmptyToUndefined(form.rankOperator),
    effective_bet_memo: convertEmptyToUndefined(form.effectiveBetMemo),
    rank_memo: convertEmptyToUndefined(form.rankMemo),
    page: 1,
    tab: 1
  } as PlayerRankEffectiveBetRequest
  return omitBy(converted, isUndefined) as PlayerRankEffectiveBetRequest
}

const getValueFromEvent: ValueGetter<PlayerRankEffectiveBetFormType> = {
  account: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  effectiveBetTypes: getValueFromValue,
  rankTypes: getValueFromValue,
  effectiveBetOperator: getValueFromChangeEvent,
  rankOperator: getValueFromChangeEvent,
  effectiveBetMemo: getValueFromChangeEvent,
  rankMemo: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)
const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])

  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])

  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])

  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      onlyDate
    />
  )
})

const PlayerRankEffectiveBetForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const writable = useChecker()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        ...request,
        time: {
          start: request.start_at === undefined ? null : new Date(request.start_at),
          end: request.end_at === undefined ? null : new Date(request.end_at)
        },
        rankTypes: request.rank_types ?? [],
        rankMemo: request.rank_memo ?? '',
        rankOperator: request.rank_operator ?? '',
        effectiveBetMemo: request.effective_bet_memo ?? '',
        effectiveBetOperator: request.effective_bet_operator ?? '',
        effectiveBetTypes: request.effective_bet_types ?? []
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.playerRankEffectiveBet.encodePath
  })
  const validation = useMemo(() => {
    return {
      account: [
        {
          func: createValidateNotEmpty<PlayerRankEffectiveBetFormType>('account', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      time: [
        {
          func: createValidateStartAtWithEndAt('time', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      effectiveBetTypes: [],
      rankTypes: [],
      effectiveBetOperator: [],
      rankOperator: [],
      effectiveBetMemo: [],
      rankMemo: []
    } as FormValidation<PlayerRankEffectiveBetFormType>
  }, [t])

  const effectiveTypesOptions = useMemo(() => {
    return Object.keys(rankEffectiveBetRecordTypeName)
      .map((key) => key as RankEffectiveBetRecordType)
      .map((key) => ({ name: t(rankEffectiveBetRecordTypeName[key]), value: key }))
  }, [t])

  const rankTypesOptions = useMemo(() => {
    return Object.keys(rankCategoryTypeName)
      .map((key) => key as RankCategoryType)
      .map((key) => ({ name: t(rankCategoryTypeName[key]), value: key }))
  }, [t])

  const navigate = useNavigate()

  const handleReload = useCallback((account: string) => {
    navigate(allRoute.playerRankEffectiveBet.encodePath({ search: { ...request, page: 1, tab: 1, account, timestamp: Number(new Date()) }, param: {} }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, request])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      {writable && (
        <Box paddingBottom={2}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <PlayerRankSetButton reload={handleReload} />
            </Grid>
          </Grid>
        </Box>
      )}
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.rankEffectiveBet')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerRankEffectiveBetFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.playerAccount')}
                      placeholder={t('placeholder.inputPlayerAccount')}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerRankEffectiveBetFormType, MultipleSelectorPropTypes>
                      context={FormContext}
                      component={MultipleSelector}
                      name="effectiveBetTypes"
                      label={t('common.effectiveBetType')}
                      options={effectiveTypesOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerRankEffectiveBetFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="effectiveBetOperator"
                      label={t('common.effectiveBetOperator')}
                      placeholder={t('placeholder.inputUpdateBy')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerRankEffectiveBetFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="effectiveBetMemo"
                      label={t('common.effectiveBetMemo')}
                      placeholder={t('placeholder.inputMemo')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerRankEffectiveBetFormType, MultipleSelectorPropTypes>
                      context={FormContext}
                      component={MultipleSelector}
                      name="rankTypes"
                      label={t('common.upgradeAndDowngradeType')}
                      options={rankTypesOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerRankEffectiveBetFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="rankOperator"
                      label={t('common.upgradeAndDowngradeOperator')}
                      placeholder={t('placeholder.inputUpdateBy')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerRankEffectiveBetFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="rankMemo"
                      label={t('common.upgradeAndDowngradeMemo')}
                      placeholder={t('placeholder.inputMemo')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerRankEffectiveBetForm)
