import React, { useMemo, createContext, useState, useContext } from 'react'
import { PaginationReq, ThirdPartyDepositSlipQuery, AdminDepositStatusType, DepositMethodType, ThirdPartyDepositCourierSimple, CashType, AgentType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { addMonths, isBefore, subSeconds, isValid, isAfter, startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getValueFromChangeEvent, guaranteeBeKey, parseFloat, convertEmptyToUndefined, getValueFromValue, getCashInputProps, parseArray } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit, useGetData } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter, ChangedFormGetter } from '../../../utils/default/FormHook'
import adminDepositStatusName from '../../../constants/admin/adminDepositStatusName'
import depositTypeName, { manualDepositTypeName } from '../../../constants/default/depositTypeName'
import cashTypeName from '../../../constants/default/cashTypeName'
import { createValidateDate, createValidateCash, createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import DateTimePicker from '../../default/form/DateTimePicker'
import RequiredText from '../../default/form/RequiredText'
import NumberInput from '../../default/form/NumberInput'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import agentTypeName from '../../../constants/default/agentTypeName'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../../../components/default/form/MultipleSelector'

export type SlipRequest = PaginationReq & ThirdPartyDepositSlipQuery

export interface SlipFormType {
  account: string
  serial: string
  status: '--' | AdminDepositStatusType
  forwarderId: '--' | number
  method: '--' | DepositMethodType
  createdAtStart: Date | null
  createdAtEnd: Date | null
  finishAtStart: Date | null
  finishAtEnd: Date | null
  cashType: CashType
  min: string
  max: string
  layer_ids: number[]
  agent_types: AgentType[]
  real_name: string
}

export const searchToRequest: SearchToRequestFunc<SlipRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    status: acceptUndefined(search.status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(adminDepositStatusName)),
      parseInt
    )),
    forwarder_id: acceptUndefined(search.forwarder_id, parseInt),
    method: acceptUndefined(search.method, pipe(
      (value) => guaranteeBeKey(value, Object.keys(depositTypeName))
    )),
    created_at_start: acceptUndefined(search.created_at_start, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    created_at_end: acceptUndefined(search.created_at_end, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    finished_at_start: acceptUndefined(search.finished_at_start, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    finished_at_end: acceptUndefined(search.finished_at_end, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    cash_start: acceptUndefined(search.cash_start, parseFloat),
    cash_end: acceptUndefined(search.cash_end, parseFloat),
    layer_ids: acceptUndefined(
      search.layer_ids,
      pipe(
        parseArray,
        (values) => values.map((item: string) => Number(item))
      )
    ),
    agent_types: acceptUndefined(
      search.agent_types,
      pipe(
        parseArray,
        (values) => values.map((item: string) => Number(item) as AgentType)
      )
    )
  } as SlipRequest
  if (converted.created_at_start && converted.created_at_end && converted.created_at_end < converted.created_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.finished_at_start && converted.finished_at_end && converted.finished_at_end < converted.finished_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.cash_start && converted.cash_end && converted.cash_end < converted.cash_start) throw new Error('The cash_end can\'t exceed the cash_start')
  return omitBy(converted, isUndefined) as SlipRequest
}

export const initialForm: InitialFormFunc<SlipFormType> = (defaultForm) => ({
  account: '',
  serial: '',
  status: '--',
  forwarderId: '--',
  method: '--',
  createdAtStart: startOfDay(new Date()),
  createdAtEnd: endOfDay(new Date()),
  finishAtStart: null,
  finishAtEnd: null,
  cashType: CashType.RMB,
  min: '',
  max: '',
  layer_ids: [],
  agent_types: [],
  real_name: '',
  ...defaultForm
})

const formToRequest = (form: SlipFormType): SlipRequest => {
  const converted = {
    account: convertEmptyToUndefined(form.account),
    order_number: convertEmptyToUndefined(form.serial),
    status: form.status === '--' ? undefined : form.status,
    forwarder_id: form.forwarderId === '--' ? undefined : form.forwarderId,
    method: form.method === '--' ? undefined : form.method,
    created_at_start: form.createdAtStart && isValid(form.createdAtStart) ? getTime(form.createdAtStart) : undefined,
    created_at_end: form.createdAtEnd && isValid(form.createdAtEnd) ? getTime(form.createdAtEnd) : undefined,
    finished_at_start: form.finishAtStart && isValid(form.finishAtStart) ? getTime(form.finishAtStart) : undefined,
    finished_at_end: form.finishAtEnd && isValid(form.finishAtEnd) ? getTime(form.finishAtEnd) : undefined,
    cash_type: form.cashType,
    cash_start: convertEmptyToUndefined(form.min),
    cash_end: convertEmptyToUndefined(form.max),
    layer_ids: form.layer_ids.length === 0 ? undefined : form.layer_ids,
    agent_types: form.agent_types.length === 0 ? undefined : form.agent_types,
    real_name: convertEmptyToUndefined(form.real_name),
    page: 1
  } as SlipRequest
  return omitBy(converted, isUndefined) as SlipRequest
}

const getValueFromEvent: ValueGetter<SlipFormType> = {
  account: getValueFromChangeEvent,
  serial: getValueFromChangeEvent,
  status: getValueFromChangeEvent,
  forwarderId: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  createdAtStart: getValueFromValue,
  createdAtEnd: getValueFromValue,
  finishAtStart: getValueFromValue,
  finishAtEnd: getValueFromValue,
  cashType: getValueFromChangeEvent,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  layer_ids: getValueFromValue,
  agent_types: getValueFromValue,
  real_name: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<SlipFormType> = {
  createdAtStart: (value, form) => {
    if (value && form.createdAtEnd && (isAfter(form.createdAtEnd, subSeconds(addMonths(value, 2), 1)) || isBefore(form.createdAtEnd, value))) {
      return { ...form, createdAtStart: value, createdAtEnd: subSeconds(addMonths(value, 2), 1) }
    }
    return { ...form, createdAtStart: value }
  },
  finishAtStart: (value, form) => {
    if (value && form.finishAtEnd && (isAfter(form.finishAtEnd, subSeconds(addMonths(value, 2), 1)) || isBefore(form.finishAtEnd, value))) {
      return { ...form, finishAtStart: value, finishAtEnd: subSeconds(addMonths(value, 2), 1) }
    }
    return { ...form, finishAtStart: value }
  }
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const cashInputProps = getCashInputProps()

type DateType = 'created' | 'finish'
const DateInput: React.FC<{ dateType: DateType }> = React.memo((props) => {
  const { dateType } = props
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const startName = useMemo(() => `${dateType}AtStart` as const, [dateType])
  const endName = useMemo(() => `${dateType}AtEnd` as const, [dateType])
  const startLabel = useMemo(() => `${dateType === 'created' ? t('common.applyTime') : t('common.confirmTime')}${t('common.beginAt')}`, [t, dateType])
  const endLabel = useMemo(() => `${dateType === 'created' ? t('common.applyTime') : t('common.confirmTime')}${t('common.endAt')}`, [t, dateType])
  const time = useMemo(() => ({ start: value[startName], end: value[endName] }), [endName, startName, value])
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const twoMonthsAgoFromStartTime = time.start ? subSeconds(addMonths(time.start, 2), 1) : new Date()
    const shouldDisableStartDate = (): boolean => {
      return false
    }
    const shouldDisableEndDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (isAfter(day, endOfDay(twoMonthsAgoFromStartTime))) return true
      if (time.start === null) return false
      return isBefore(day, time.start)
    }
    return {
      shouldDisableStartDate,
      shouldDisableEndDate
    }
  }, [time.start])
  return (<>
    <Grid item xs={12} md={3}>
      <DateTimePicker
        value={time.start}
        onChange={handleChange(startName)}
        label={startLabel}
        shouldDisableDate={shouldDisableStartDate}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <DateTimePicker
        value={time.end}
        onChange={handleChange(endName)}
        label={endLabel}
        shouldDisableDate={shouldDisableEndDate}
      />
    </Grid>
  </>)
})

const DepositThirdPartySlipForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const layers = useGDKStore.player.layers()
  const [couriers, setCouriers] = useState<ThirdPartyDepositCourierSimple[]>([])

  useGetData({
    gdkFunc: () => gdk.deposit.getCourierList(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setCouriers(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm(omitBy({
        account: request.account,
        serial: request.order_number,
        status: request.status,
        forwarderId: request.forwarder_id,
        method: request.method,
        createdAtStart: request.created_at_start ? new Date(request.created_at_start) : null,
        createdAtEnd: request.created_at_end ? new Date(request.created_at_end) : null,
        finishAtStart: request.finished_at_start ? new Date(request.finished_at_start) : null,
        finishAtEnd: request.finished_at_end ? new Date(request.finished_at_end) : null,
        cashType: request.cash_type,
        min: request.cash_start?.toString() ?? '',
        max: request.cash_end?.toString() ?? '',
        layer_ids: request.layer_ids ?? [],
        agent_types: request.agent_types ?? [],
        real_name: request.real_name
      }, isUndefined))
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.depositThirdPartySlip.encodePath
  })

  const validation = useMemo(() => ({
    account: [],
    serial: [],
    status: [],
    forwarderId: [],
    method: [],
    createdAtStart: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { createdAtStart: null } }
          }
          return createValidateDate('createdAtStart', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.createdAtEnd === null) {
            return { isPass: true, stop: false, newError: { createdAtStart: null } }
          }
          if (isAfter(value as Date, form.createdAtEnd)) {
            return { isPass: false, stop: true, newError: { createdAtStart: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { createdAtStart: null, createdAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    createdAtEnd: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { createdAtEnd: null } }
          }
          return createValidateDate('createdAtEnd', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.createdAtStart === null) {
            return { isPass: true, stop: false, newError: { createdAtEnd: null } }
          }
          if (isAfter(form.createdAtStart, value as Date)) {
            return { isPass: false, stop: true, newError: { createdAtEnd: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { createdAtStart: null, createdAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    finishAtStart: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { finishAtStart: null } }
          }
          return createValidateDate('finishAtStart', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.finishAtEnd === null) {
            return { isPass: true, stop: false, newError: { finishAtStart: null } }
          }
          if (isAfter(value as Date, form.finishAtEnd)) {
            return { isPass: false, stop: true, newError: { finishAtStart: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { finishAtStart: null, finishAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    finishAtEnd: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { finishAtEnd: null } }
          }
          return createValidateDate('finishAtEnd', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.finishAtStart === null) {
            return { isPass: true, stop: false, newError: { finishAtEnd: null } }
          }
          if (isAfter(form.finishAtStart, value as Date)) {
            return { isPass: false, stop: true, newError: { finishAtEnd: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { finishAtStart: null, finishAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    cashType: [],
    min: [
      {
        func: createValidateCash('min', t),
        when: ['change', 'beforeClickSubmit']
      },
      {
        func: createValidateMin('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    max: [
      {
        func: createValidateCash('max', t),
        when: ['change', 'beforeClickSubmit']
      },
      {
        func: createValidateMax('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    layer_ids: [],
    agent_types: [],
    real_name: []
  } as FormValidation<SlipFormType>), [t])

  const statusOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(adminDepositStatusName)
      .map((key) => Number(key) as AdminDepositStatusType)
      .map((key) => ({
        name: t(adminDepositStatusName[key]),
        value: key
      })) as any
  )

  const courierOptions = [{ name: t('common.all'), value: '--' }].concat(
    couriers.map((courier) => ({
      name: courier.name,
      value: courier.id
    })) as any
  )

  const methodOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(depositTypeName)
      .filter((key) => key !== DepositMethodType.CGPAY_USDT && !Object.keys(manualDepositTypeName).includes(key))
      .map((key) => ({ name: t(depositTypeName[key as DepositMethodType]), value: key })) as any
  )

  const playerLayerOptions = useMemo(() => layers.map((item) => ({ name: item.name, value: item.id })), [layers])

  const agentLayerOptions = useMemo(() => Object.keys(agentTypeName)
    .map((key) => Number(key) as AgentType).map((value) => ({ name: t(agentTypeName[value]), value })), [t])

  const cashTypeOptions = Object.entries(cashTypeName).map(([value, name]) => ({ name, value }))

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.thirdPartyDepositSlip')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.account')}
                      placeholder={t('placeholder.inputAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="serial"
                      label={t('common.orderNumber')}
                      placeholder={t('placeholder.inputOrderNumber')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="status"
                      options={statusOptions}
                      label={t('common.orderStatus2')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="forwarderId"
                      options={courierOptions}
                      label={t('common.courierName')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="method"
                      label={t('common.depositWay')}
                      options={methodOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="real_name"
                      label={t('common.depositName')}
                      placeholder={t('placeholder.inputDepositName')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <DateInput dateType="created" />
                  <DateInput dateType="finish" />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="cashType"
                      label={t('common.orderMoney')}
                      options={cashTypeOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="min"
                      label={t('common.minOrderMoney')}
                      placeholder={t('placeholder.inputMinOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="max"
                      label={t('common.maxOrderMoney')}
                      placeholder={t('placeholder.inputMaxOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<SlipFormType, MultipleSelectorProps>
                      context={FormContext}
                      component={MultipleSelector}
                      name="layer_ids"
                      label={t('common.playerLayer')}
                      options={playerLayerOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<SlipFormType, MultipleSelectorProps>
                      context={FormContext}
                      component={MultipleSelector}
                      name="agent_types"
                      label={t('common.agentLayer')}
                      options={agentLayerOptions}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(DepositThirdPartySlipForm)
