import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import EditIcon from '@material-ui/icons/Edit'
import IconWithBadge from '../../default/present/IconWithBadge'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const ComplainIcon: React.FC<SvgIconProps> = (props) => {
  const me = useGDKStore.admin.me()
  const hasComplain = me?.has_complain ?? false
  const badgeContent = hasComplain ? 1 : 0
  return (
    <IconWithBadge
      icon={EditIcon}
      badgeContent={badgeContent}
      {...props}
    />
  )
}

export default React.memo(ComplainIcon)
