import React, { useMemo, createContext, useContext, useCallback } from 'react'
import { PaginationReq, OperationHistoryQuery, PermissionType, OperationType } from '@golden/gdk-admin'
import { omitBy, isUndefined, unionBy } from '@golden/utils'
import { startOfDay, endOfDay, subDays, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, guaranteeBeOneOf, acceptUndefined, convertEmptyToUndefined, getTimeFromDateInputValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import operationTypeName from '../../../constants/admin/operationTypeName'
import GroupKey from '../navigation/GroupKey'
import allRoutes from '../route/route'
import RouteKey from '../route/RouteKey'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth } from '../../../utils/default/TimeHelper'
import menuPermissionMap from '../../../constants/admin/menuPermissionMap'

export type JournalOperationRequest = PaginationReq & OperationHistoryQuery & { group: GroupKey, route: RouteKey }

export interface JournalOperationFormType {
  time: DateInputValue
  account: string
  operation_type: OperationType | '--'
  group: GroupKey | '--'
  route: RouteKey | '--'
}

const convertGroupAndRouteToPermission = (groupKey: GroupKey | undefined, routeKey: RouteKey | undefined): PermissionType[] | undefined => {
  if (routeKey) {
    return menuPermissionMap
      .filter((item) => item.route === routeKey)
      .map((item) => item.permissions)
      .reduce((arr, item) => arr.concat(item), [])
  }
  if (groupKey) {
    return menuPermissionMap
      .filter((item) => item.group === groupKey)
      .map((item) => item.permissions)
      .reduce((arr, item) => arr.concat(item), [])
  }
  return undefined
}

export const searchToRequest: SearchToRequestFunc<JournalOperationRequest> = (search) => {
  const nintyDaysAgo = getTime(startOfDay(subDays(new Date(), 90)))
  const endOfToday = getTime(endOfDay(new Date()))
  const group = acceptUndefined(search.group, pipe(
    (value) => guaranteeBeOneOf(value, Object.values(GroupKey))
  ))
  const route = acceptUndefined(search.route, pipe(
    value => guaranteeBeOneOf(value, Object.values(RouteKey))
  ))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, nintyDaysAgo, endOfToday)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, nintyDaysAgo, endOfToday)
    )(search.end_at),
    operation_type: acceptUndefined(search.operation_type, pipe(
      (value) => guaranteeBeOneOf(value, Object.keys(operationTypeName)),
      parseInt
    )),
    group,
    route,
    permissions: convertGroupAndRouteToPermission(group, route)
  } as JournalOperationRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as JournalOperationRequest
}

export const initialForm: InitialFormFunc<JournalOperationFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  account: '',
  operation_type: '--',
  group: '--',
  route: '--',
  ...defaultForm
})

const formToRequest = (form: JournalOperationFormType): JournalOperationRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: convertEmptyToUndefined(form.account),
    operation_type: form.operation_type === '--' ? undefined : form.operation_type,
    group: form.group === '--' ? undefined : form.group,
    route: form.route === '--' ? undefined : form.route,
    page: 1
  } as JournalOperationRequest
  return omitBy(converted, isUndefined) as JournalOperationRequest
}

const getValueFromEvent: ValueGetter<JournalOperationFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  operation_type: getValueFromChangeEvent,
  group: getValueFromChangeEvent,
  route: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const nintyDaysAgo = startOfDay(subDays(new Date(), 90))
    return createShouldDisableDate(value.time.start, value.time.end, nintyDaysAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const SubMenu: React.FC = () => {
  const { t } = useT()
  const { value, handleChange, dispatch } = useContext(FormContext)
  const subOptions = useMemo(() => {
    dispatch({ type: 'change', label: 'route', value: '--' })
    return [{ name: t('common.all'), value: '--' }].concat(
      unionBy(
        menuPermissionMap
          .filter((item) => (item.group !== GroupKey.JOURNAL))
          .filter((item) => {
            if (value.group === '--') return true
            return item.group === value.group
          })
          .map((item) => ({ name: t(item.routeName), value: item.route })),
        'value'
      )
    )
  }, [value.group, t])
  return (
    <DropDown
      options={subOptions}
      value={value.route}
      onChange={handleChange('route')}
      label={t('common.modifiedSubMenu')}
      fullWidth
    />
  )
}

const JournalOperationForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.journalOperation.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    account: [],
    operation_type: [],
    group: [],
    route: []
  } as FormValidation<JournalOperationFormType>), [])

  const operationOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(operationTypeName)
      .map((key) => Number(key) as OperationType)
      .map((key) => ({
        name: t(operationTypeName[key]),
        value: key
      })) as any
  )

  const mainOptions = [{ name: t('common.all'), value: '--' }].concat(
    unionBy(
      menuPermissionMap.filter((item) => (item.group !== GroupKey.JOURNAL)).map((item) => ({ name: t(item.groupName), value: item.group })),
      'value'
    )
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.operationHistory')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<JournalOperationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.updateBy')}
                    placeholder={t('placeholder.inputPlayerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<JournalOperationFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="operation_type"
                    options={operationOptions}
                    label={t('common.operationType')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<JournalOperationFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="group"
                    options={mainOptions}
                    label={t('common.modifiedMainMenu')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <SubMenu />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(JournalOperationForm)
