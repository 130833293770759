import React, { useContext, useMemo, useCallback, createContext } from 'react'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import { getTime, startOfDay, endOfDay, subYears, subMonths } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { FrequencyType, CompanyStatisticQuery, GameCategoryType } from '@golden/gdk-admin'
import { setLastMonth, createShouldDisableDate, setThisMonth } from '../../../utils/default/TimeHelper'
import FormStateProvider from '../../default/form/FormStateProvider'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getTimeFromDateInputValue, getValueFromChangeEvent, guaranteeNotUndefined, pipe, parseInt, guaranteeBeKey, guaranteeBetween, convertEmptyToUndefined, acceptUndefined, guaranteeBeOneOf, getValueFromValue } from '../../../utils/default/FormHelper'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { InitialFormFunc, useChangeUrlSubmit, SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../route/route'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import frequencyName from '../../../constants/admin/frequencyName'
import FormField from '../../default/form/FormField'
import { createCorrectResult, createValidateNotEmpty } from '../../../utils/default/Validator'
import GameCategoryInput, { PropTypes as GameCategoryProps } from '../GameCategoryInput'

export interface CompanyStatisticFormType {
  period: FrequencyType
  time: DateInputValue
  type: GameCategoryType | 'all'
  accountType: 'agent' | 'player' | '--'
  account: string
}

export const initialForm: InitialFormFunc<CompanyStatisticFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  period: FrequencyType.DAY,
  type: 'all',
  accountType: '--',
  account: '',
  ...defaultForm
})

const formToRequest = (form: CompanyStatisticFormType): CompanyStatisticQuery => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    period: form.period,
    game_category: form.type === 'all' ? undefined : form.type,
    staff: form.accountType === 'agent' ? convertEmptyToUndefined(form.account) : undefined,
    account: form.accountType === 'player' ? convertEmptyToUndefined(form.account) : undefined
  } as CompanyStatisticQuery
  return omitBy(converted, isUndefined) as CompanyStatisticQuery
}

const getChangedForm: ChangedFormGetter<CompanyStatisticFormType> = {
  period: (value, form) => ({ ...form, period: value, time: { start: startOfDay(new Date()), end: endOfDay(new Date()) } }),
  accountType: (value, form) => ({ ...form, accountType: value, account: value === '--' ? '' : form.account })
}

export const searchToRequest: SearchToRequestFunc<CompanyStatisticQuery> = (search) => {
  const converted = {
    ...search,
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt
    )(search.end_at),
    period: pipe(
      guaranteeNotUndefined,
      (value) => guaranteeBeKey(value, Object.keys(frequencyName)),
      parseInt
    )(search.period),
    game_category: acceptUndefined(
      search.game_category,
      pipe(
        (value) => guaranteeBeOneOf(Number(value), enumValues(GameCategoryType)),
        parseInt
      )
    )
  } as CompanyStatisticQuery
  const fiveYearsAgo = subYears(startOfDay(new Date()), 5)
  const oneYearAgo = subYears(startOfDay(new Date()), 1)
  const threeMonthsAgo = subMonths(startOfDay(new Date()), 3)
  const today = endOfDay(new Date())
  let limit = threeMonthsAgo
  if (converted.period === FrequencyType.DAY) limit = threeMonthsAgo
  if (converted.period === FrequencyType.WEEK) limit = oneYearAgo
  if (converted.period === FrequencyType.MONTH) limit = fiveYearsAgo
  guaranteeBetween(converted.start_at, getTime(limit), getTime(today))
  guaranteeBetween(converted.end_at, getTime(limit), getTime(today))
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as CompanyStatisticQuery
}

const getValueFromEvent: ValueGetter<CompanyStatisticFormType> = {
  period: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  type: getValueFromValue,
  accountType: getValueFromChangeEvent,
  account: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const FrequencyInput: React.FC = React.memo(() => {
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const options = useMemo(() => {
    return Object.keys(frequencyName)
      .map((key) => Number(key) as FrequencyType)
      .map((key) => ({ name: t(frequencyName[key]), value: key }))
  }, [t])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('period'), [])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        label={t('common.frequency')}
        options={options}
        value={value.period}
        onChange={onChange}
        fullWidth
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const fiveYearsAgo = useMemo(() => subYears(startOfDay(new Date()), 5), [])
  const oneYearAgo = useMemo(() => subYears(startOfDay(new Date()), 1), [])
  const threeMonthsAgo = useMemo(() => subMonths(startOfDay(new Date()), 3), [])
  const today = endOfDay(new Date())
  const limit = useMemo(() => {
    let limit = threeMonthsAgo
    if (value.period === FrequencyType.DAY) limit = threeMonthsAgo
    if (value.period === FrequencyType.WEEK) limit = oneYearAgo
    if (value.period === FrequencyType.MONTH) limit = fiveYearsAgo
    return limit
  }, [value.period, fiveYearsAgo, oneYearAgo, threeMonthsAgo])
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    return createShouldDisableDate(value.time.start, value.time.end, limit, today)
  }, [limit, value.time, today])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={FrequencyInput}
      denseToolbar
      onlyDate
    />
  )
})

const AccountInput: React.FC = React.memo(() => {
  const { value, handleChange, error } = useContext(FormContext)
  const { t } = useT()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('account'), [])
  return (
    <TextField
      label={t('common.account')}
      value={value.account}
      onChange={onChange}
      helperText={error.account ?? (value.accountType === 'agent' ? t('helperText.goldenTip') : '')}
      error={error.account !== null}
      fullWidth
      disabled={value.accountType === '--'}
    />
  )
})

const Tip: React.FC = React.memo(() => {
  const { value } = useContext(FormContext)
  const { t } = useT()
  if (value.accountType === 'player') {
    return (
      <Box whiteSpace="pre">
        <Typography color="error">
          {t('common.playerStatisticTip')}
        </Typography>
      </Box>
    )
  }
  return (
    <Box whiteSpace="pre">
      <Typography color="error">
        {t('common.companyStatisticTip')}
      </Typography>
    </Box>
  )
})

interface PropTypes {
  request: CompanyStatisticQuery | undefined
}

const AdminReportCompanyStatisticForm: React.FC<PropTypes> = (props) => {
  const { request } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.adminReportCompanyStatistic.encodePath
  })
  const validation = useMemo(() => {
    return {
      time: [],
      period: [],
      type: [],
      accountType: [],
      account: [
        {
          func: (value, form, lastSubmitForm, error) => {
            if (form.accountType === '--') {
              return createCorrectResult('account')
            }
            return createValidateNotEmpty('account', t)(value, form, lastSubmitForm, error)
          },
          when: ['rerender', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<CompanyStatisticFormType>
  }, [t])
  const defaultForm = useMemo(() => {
    if (request) {
      let accountType: 'agent' | 'player' | '--' = '--'
      if (request.staff) accountType = 'agent'
      if (request.account) accountType = 'player'
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        period: request.period,
        type: request.game_category ?? 'all',
        accountType,
        account: request.staff ?? request.account ?? ''
      })
    }
    return initialForm()
  }, [request])
  const accountTypeOptions = useMemo(() => {
    return [
      { name: t('placeholder.inputAccountType'), value: '--' },
      { name: t('common.agentAccount'), value: 'agent' },
      { name: t('common.playerAccount'), value: 'player' }
    ]
  }, [t])
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.companyStatistic')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<CompanyStatisticFormType, GameCategoryProps>
                    context={FormContext}
                    component={GameCategoryInput}
                    name="type"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<CompanyStatisticFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="accountType"
                    label={t('common.accountType')}
                    options={accountTypeOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <AccountInput />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Tip />
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(AdminReportCompanyStatisticForm)
