import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { DepositStatisticQuery } from '@golden/gdk-admin'
import { getTime, startOfDay, endOfDay, startOfYear, subYears, isAfter, isBefore } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../route/route'
import { createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import { setToday, setYesterday, setThisMonth, setLastMonth } from '../../../utils/default/TimeHelper'

export interface DepositStatisticFormType {
  time: DateInputValue
}

export const searchToRequest: SearchToRequestFunc<DepositStatisticQuery> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const converted = {
    ...search,
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.end_at)
  } as DepositStatisticQuery
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as DepositStatisticQuery
}

export const initialForm: InitialFormFunc<DepositStatisticFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  family: '--',
  publisher: '--',
  drawDate: '',
  ...defaultForm
})

const formToRequest = (form: DepositStatisticFormType): DepositStatisticQuery => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!)
  }
}

const getValueFromEvent: ValueGetter<DepositStatisticFormType> = {
  time: getTimeFromDateInputValue
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const fiveYearsAgo = useMemo(() => startOfYear(subYears(new Date(), 5)), [])
  const shouldDisableStartDate = useCallback((day: Date | null): boolean => {
    if (day === null) return true
    if (isBefore(day, fiveYearsAgo)) return true
    if (value.time.end === null) return true
    return isAfter(day, value.time.end)
  }, [fiveYearsAgo, value.time.end])
  const shouldDisableEndDate = useCallback((day: Date | null): boolean => {
    if (day === null) return true
    if (value.time.start === null) return isBefore(day, fiveYearsAgo)
    return isBefore(day, value.time.start)
  }, [fiveYearsAgo, value.time.start])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const AdminReportDepositStatisticForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        }
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.adminReportDepositStatistic.encodePath
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ]
  } as FormValidation<DepositStatisticFormType>), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.depositStatisticSearch')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default AdminReportDepositStatisticForm
