import React, { useMemo, createContext, useContext } from 'react'
import { PaginationReq, ManualDepositType, ManualDepositQuery } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { isAfter, startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getValueFromChangeEvent, guaranteeBeKey, parseFloat, convertEmptyToUndefined, getValueFromValue } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createValidateDate, createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import DateTimePicker from '../../default/form/DateTimePicker'
import RequiredText from '../../default/form/RequiredText'
import NumberInput from '../../default/form/NumberInput'
import { createShouldDisableDate } from '../../../utils/default/TimeHelper'
import manualDepositName from '../../../constants/admin/manualDepositName'
import ManualDespositProcessTable from './ManualDespositProcessTable'

export type ManualDepositRequest = PaginationReq & ManualDepositQuery

export interface ManualDepositFormType {
  account: string
  order_number: string
  method: ManualDepositType | '--'
  start_at: Date | null
  end_at: Date | null
  created_at_start: Date | null
  created_at_end: Date | null
  cash_start: string | number
  cash_end: string | number
  agent_account: string
  batch_id: string
  binding_deposit_order_number: string
}

export const searchToRequest: SearchToRequestFunc<ManualDepositRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    method: acceptUndefined(search.method, pipe(
      (value) => guaranteeBeKey(value, Object.keys(manualDepositName))
    )),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    created_at_start: acceptUndefined(search.created_at_start, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    created_at_end: acceptUndefined(search.created_at_end, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    cash_start: acceptUndefined(search.cash_start, parseFloat),
    cash_end: acceptUndefined(search.cash_end, parseFloat)
  } as ManualDepositRequest
  if (converted.start_at && converted.end_at && converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  if (converted.cash_start && converted.cash_end && converted.cash_end < converted.cash_start) throw new Error('The cash_end can\'t exceed the cash_start')
  return omitBy(converted, isUndefined) as ManualDepositRequest
}

export const initialForm: InitialFormFunc<ManualDepositFormType> = (defaultForm) => ({
  account: '',
  order_number: '',
  method: '--',
  status: '--',
  start_at: startOfDay(new Date()),
  end_at: endOfDay(new Date()),
  created_at_start: null,
  created_at_end: null,
  cash_start: '',
  cash_end: '',
  agent_account: '',
  batch_id: '',
  binding_deposit_order_number: '',
  ...defaultForm
})

const formToRequest = (form: ManualDepositFormType): ManualDepositRequest => {
  const cashStart = convertEmptyToUndefined(form.cash_start as string)
  const cashEnd = convertEmptyToUndefined(form.cash_end as string)
  const converted = {
    account: convertEmptyToUndefined(form.account),
    order_number: convertEmptyToUndefined(form.order_number),
    method: form.method === '--' ? undefined : form.method,
    start_at: form.start_at ? getTime(form.start_at) : undefined,
    end_at: form.end_at ? getTime(form.end_at) : undefined,
    created_at_start: form.created_at_start ? getTime(form.created_at_start) : undefined,
    created_at_end: form.created_at_end ? getTime(form.created_at_end) : undefined,
    cash_start: cashStart ? Number(cashStart) : undefined,
    cash_end: cashEnd ? Number(cashEnd) : undefined,
    agent_account: convertEmptyToUndefined(form.agent_account),
    batch_id: convertEmptyToUndefined(form.batch_id),
    binding_deposit_order_number: convertEmptyToUndefined(form.binding_deposit_order_number),
    page: 1
  } as ManualDepositRequest
  return omitBy(converted, isUndefined) as ManualDepositRequest
}

const getValueFromEvent: ValueGetter<ManualDepositFormType> = {
  account: getValueFromChangeEvent,
  order_number: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  start_at: getValueFromValue,
  end_at: getValueFromValue,
  created_at_start: getValueFromValue,
  created_at_end: getValueFromValue,
  cash_start: getValueFromChangeEvent,
  cash_end: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  batch_id: getValueFromChangeEvent,
  binding_deposit_order_number: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const moneyInputProps = {
  thousandSeparator: true,
  decimalScale: 0,
  allowNegative: false,
  fixedDecimalScale: true
}

const DateRow: React.FC = React.memo(() => {
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const {
    shouldDisableStartDate: shouldDisableCreateStartDate,
    shouldDisableEndDate: shouldDisableCreateEndDate
  } = useMemo(() => {
    const past = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.start_at, value.end_at, past)
  }, [value.start_at, value.end_at])
  return (
    <React.Fragment>
      <Grid item xs={12} md={3}>
        <DateTimePicker
          value={value.start_at}
          onChange={handleChange('start_at')}
          label={`${t('common.updateAt')}${t('common.beginAt')}`}
          shouldDisableDate={shouldDisableCreateStartDate}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DateTimePicker
          value={value.end_at}
          onChange={handleChange('end_at')}
          label={`${t('common.updateAt')}${t('common.endAt')}`}
          shouldDisableDate={shouldDisableCreateEndDate}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DateTimePicker
          value={value.created_at_start}
          onChange={handleChange('created_at_start')}
          label={`${t('common.createdAt')}${t('common.beginAt')}`}
          shouldDisableDate={shouldDisableCreateStartDate}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DateTimePicker
          value={value.created_at_end}
          onChange={handleChange('created_at_end')}
          label={`${t('common.createdAt')}${t('common.endAt')}`}
          shouldDisableDate={shouldDisableCreateEndDate}
        />
      </Grid>
    </React.Fragment>
  )
})

const ManualDepositForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    return initialForm({
      ...request,
      method: request?.method ?? '--',
      created_at_start: request?.created_at_start ? new Date(request.created_at_start) : null,
      created_at_end: request?.created_at_end ? new Date(request.created_at_end) : null,
      start_at: request ? (request.start_at ? new Date(request.start_at) : null) : startOfDay(new Date()),
      end_at: request ? (request.end_at ? new Date(request.end_at) : null) : endOfDay(new Date())
    })
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.manualDeposit.encodePath
  })

  const validation = useMemo(() => ({
    account: [],
    order_number: [],
    method: [],
    status: [],
    start_at: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { start_at: null } }
          }
          return createValidateDate('start_at', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.end_at === null) {
            return { isPass: true, stop: false, newError: { start_at: null } }
          }
          if (isAfter(value as Date, form.end_at)) {
            return { isPass: false, stop: true, newError: { start_at: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { start_at: null, end_at: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    end_at: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { end_at: null } }
          }
          return createValidateDate('end_at', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.start_at === null) {
            return { isPass: true, stop: false, newError: { end_at: null } }
          }
          if (isAfter(form.start_at, value as Date)) {
            return { isPass: false, stop: true, newError: { end_at: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { start_at: null, end_at: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    created_at_start: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { created_at_start: null } }
          }
          return createValidateDate('created_at_start', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.created_at_end === null) {
            return { isPass: true, stop: false, newError: { created_at_start: null } }
          }
          if (isAfter(value as Date, form.created_at_end)) {
            return { isPass: false, stop: true, newError: { created_at_start: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { created_at_start: null, created_at_end: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    created_at_end: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { created_at_end: null } }
          }
          return createValidateDate('created_at_end', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.created_at_start === null) {
            return { isPass: true, stop: false, newError: { created_at_end: null } }
          }
          if (isAfter(form.created_at_start, value as Date)) {
            return { isPass: false, stop: true, newError: { created_at_end: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { created_at_start: null, created_at_end: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    cash_start: [
      {
        func: createValidateMin('cash_start', 'cash_end', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    cash_end: [
      {
        func: createValidateMax('cash_start', 'cash_end', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    agent_account: [],
    batch_id: [],
    binding_deposit_order_number: []
  } as FormValidation<ManualDepositFormType>), [t])

  const options = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(manualDepositName)
      .map((key) => ({
        name: t(manualDepositName[key as ManualDepositType]),
        value: key
      })) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <ManualDespositProcessTable />
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.manualDeposit')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ManualDepositFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    placeholder={t('placeholder.inputPlayerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualDepositFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="method"
                    options={options}
                    label={t('common.depositObject')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualDepositFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="order_number"
                    label={t('common.serialNumber')}
                    placeholder={t('placeholder.inputSerialNumber')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormField<ManualDepositFormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="agent_account"
                  label={t('common.agentAccount')}
                  placeholder={t('placeholder.inputAgentAccount')}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <DateRow />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ManualDepositFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="cash_start"
                    label={t('common.minDepositCash')}
                    placeholder={t('placeholder.inputMinDepositCash')}
                    inputProps={moneyInputProps}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualDepositFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="cash_end"
                    label={t('common.maxDepositCash')}
                    placeholder={t('placeholder.inputMaxDepositCash')}
                    inputProps={moneyInputProps}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualDepositFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="batch_id"
                    label={t('common.batchNumber')}
                    placeholder={t('placeholder.pleaseInput', { item: t('common.batchNumber') })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManualDepositFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="binding_deposit_order_number"
                    label={t('common.exchangedDepositOrderNo')}
                    placeholder={t('placeholder.pleaseInput', { item: t('common.exchangedDepositOrderNo') })}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ManualDepositForm)
