import React, { useMemo, createContext, useContext, useCallback } from 'react'
import {
  PaginationReq, ComplainQuery
} from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import {
  getValueFromChangeEvent,
  getTimeFromDateInputValue,
  convertEmptyToUndefined,
  pipe,
  parseInt,
  guaranteeBetween,
  guaranteeNotUndefined,
  acceptUndefined,
  guaranteeBeOneOf
} from '../../../utils/default/FormHelper'
import { InitialFormFunc, useChangeUrlSubmit, useRequestFromSearch, SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import FormStateProvider from '../../default/form/FormStateProvider'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import {
  createShouldDisableDate,
  setToday,
  setYesterday
} from '../../../utils/default/TimeHelper'
import allRoute from '../route/route'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'

export type ComplainRequest = PaginationReq & ComplainQuery

export interface ComplainFormType {
  time: DateInputValue
  account: string
  isFinished: number | '--'
}

export const initialForm: InitialFormFunc<ComplainFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  account: '',
  isFinished: '--',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<ComplainFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  isFinished: getValueFromChangeEvent
}

const formToRequest = (form: ComplainFormType): ComplainRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: convertEmptyToUndefined(form.account),
    is_finished: form.isFinished === '--' ? undefined : form.isFinished,
    page: 1
  } as ComplainRequest
  const omited = omitBy(converted, isUndefined)
  return omited as ComplainRequest
}

export const searchToRequest: SearchToRequestFunc<ComplainRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.end_at),
    is_finished: acceptUndefined(search.is_finished, pipe(
      parseInt,
      (value) => guaranteeBeOneOf(value, [0, 1])
    ))
  } as ComplainRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as ComplainRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const ComplainForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        account: request.account ?? '',
        isFinished: request.is_finished === undefined ? '--' : request.is_finished
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.complain.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    account: [],
    isFinished: []
  } as FormValidation<ComplainFormType>), [])

  const statusOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' },
      { name: t('common.unprocessed'), value: 0 },
      { name: t('common.processed'), value: 1 }
    ]
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('common.complainSearch')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<ComplainFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.complainAccount')}
                      placeholder={t('placeholder.inputComplainAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<ComplainFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="isFinished"
                      label={t('common.processStatus')}
                      fullWidth
                      options={statusOptions}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ComplainForm)
