import React, { useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetData, useDialogHandleClick, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import RequiredText from '../../../components/default/form/RequiredText'

const SystemPlatformMaintenancePage: React.FC = () => {
  const commonClasses = useCommonStyles()
  const [isActive, setIsActive] = useState<boolean>(false)
  const { reload, reloadFlag } = useReload()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()
  const writable = useChecker()
  useGetData({
    gdkFunc: () => gdk.parameter.getPlatformMaintenance(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setIsActive(res.is_player_maintenance || false)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const handleSubmit = useDialogHandleClick({
    dialogId: 'updateParameter',
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdateWebsiteMaintenance')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updateWebsiteMaintenanceSuccess')
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      message: error
    }), []),
    payload: isActive,
    gdkFunc: (payload) => gdk.parameter.updatePlatformMaintenance(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: reload
  })

  return (
    <Box padding={5}>
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={commonClasses.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.platformMaintenance')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormControl component="fieldset">
                      <FormLabel>{t('common.isStartPlatformMaintenance') + '*'}</FormLabel>
                      <RadioGroup
                        row
                        value={isActive ? 1 : 0}
                        onChange={useCallback((event) => setIsActive(Boolean(Number(event.target.value))), [])}
                      >
                        <FormControlLabel disabled={!writable} value={1} control={<Radio />} label={t('common.open')} />
                        <FormControlLabel disabled={!writable} value={0} control={<Radio />} label={t('common.close')} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
              {writable && (
                <Grid item>
                  <Grid container justify="flex-end">
                    <Button
                      className={commonClasses.purpleGradualButton}
                      onClick={handleSubmit}
                    >
                      {t('common.saveSetting')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(SystemPlatformMaintenancePage)
