import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  PaginationRes,
  Complain
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { searchToRequest } from './ComplainForm'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import ComplainButtons from './ComplainButtons'

const useStyles = makeStyles(() => ({
  content: {
    width: 240
  }
}))

interface RowType {
  id: number
  account: string
  complainAt: React.ReactElement
  complain: React.ReactElement
  status: React.ReactElement
  reply: React.ReactElement
  operateAt: React.ReactElement
  operateBy: string
  functions: React.ReactElement
}

const ComplainTable: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const writable = useChecker()
  const [list, setList] = useState<PaginationRes<Complain[]>>(createDefaultPaginationData([]))
  const { reloadFlag, reload } = useReload()
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.complain.getComplainList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Complain[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        account: item.account,
        complainAt: (<DateTime time={item.complain_at} />),
        complain: (
          <Box>
            <Typography className={commonClasses.bold}>{item.title}</Typography>
            <Typography className={clsx(commonClasses.ellipsis, classes.content)}>{item.complain_content}</Typography>
          </Box>
        ),
        status: (
          <span className={commonClasses.chipText} style={{ backgroundColor: item.is_finished ? '#72d476' : '#f89f0b' }}>
            {item.is_finished ? t('common.processed') : t('common.unprocessed')}
          </span>
        ),
        reply: item.is_finished ? (<Typography className={clsx(commonClasses.ellipsis, classes.content)}>{item.reply_content}</Typography>) : (<p>--</p>),
        operateAt: item.is_finished ? (<DateTime time={item.reply_at} />) : (<p>--</p>),
        operateBy: item.is_finished ? item.reply_by : '--',
        functions: (
          <ComplainButtons
            id={item.id}
            item={item}
            reload={reload}
            writable={writable}
          />
        )
      } as RowType
    })
  }, [classes.content, commonClasses.bold, commonClasses.chipText, commonClasses.ellipsis, list.data, reload, t, writable])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.complainAccount'),
          value: 'account',
          align: 'center'
        },
        complainAt: {
          label: t('common.complainAt'),
          value: 'complainAt',
          align: 'center'
        },
        complain: {
          label: t('common.complainContent'),
          value: 'complain',
          align: 'left'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        reply: {
          label: t('common.replyContent'),
          value: 'reply',
          align: 'left'
        },
        operateAt: {
          label: t('common.updateAt'),
          value: 'operateAt',
          align: 'center'
        },
        operateBy: {
          label: t('common.updateBy'),
          value: 'operateBy',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      [
        'account',
        'complainAt',
        'complain',
        'status',
        'reply',
        'operateAt',
        'operateBy',
        'functions'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.complain.encodePath
  })
  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={request.page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default ComplainTable
