import React, { useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import NumberInput from '../../../components/default/form/NumberInput'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, FormState, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getCashInputProps } from '../../../utils/default/FormHelper'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import FormField from '../../default/form/FormField'
import { WithdrawLimitForm } from '@golden/gdk-admin'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import RequiredText from '../../default/form/RequiredText'

export interface EditWithdrawalLimitFormType {
  account: string
  cashMax: string
  daily: string
  dailyCount: string
}

export const initialWithdrawalLimitForm: InitialFormFunc<EditWithdrawalLimitFormType> = (defaultForm) => ({
  account: '',
  cashMax: '',
  daily: '',
  dailyCount: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditWithdrawalLimitFormType> = {
  account: getValueFromChangeEvent,
  cashMax: getValueFromChangeEvent,
  daily: getValueFromChangeEvent,
  dailyCount: getValueFromChangeEvent
}

export const formToRequest = (form: EditWithdrawalLimitFormType): WithdrawLimitForm => {
  return {
    account: form.account,
    cash_max_withdraw: Number(form.cashMax),
    cash_max_withdraw_daily: Number(form.daily),
    withdraw_times_daily: Number(form.dailyCount)
  } as WithdrawLimitForm
}

const cashInputProps = getCashInputProps()

interface PropTypes {
  context: React.Context<FormState<EditWithdrawalLimitFormType>>
  defaultValue: EditWithdrawalLimitFormType
  title: string
  okText: string
  onCancel: () => void
  onSubmit: (form: EditWithdrawalLimitFormType) => EditWithdrawalLimitFormType
  disableAccount?: boolean
}

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const EditWithdrawalLimitForm: React.FC<PropTypes> = (props) => {
  const { context, defaultValue, title, okText, onCancel, onSubmit, disableAccount } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const validation = useMemo(() => {
    return {
      account: [
        {
          func: createValidateNotEmpty('account', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      cashMax: [
        {
          func: createValidateNotEmpty('cashMax', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form, _) => {
            let newError: { cashMax: null | string, daily: null | string } = {
              cashMax: null,
              daily: null
            }
            if (Number(value) < 0) {
              newError = { ...newError, cashMax: t('error.mustMoreThanMin') }
            }
            if (form.daily !== '' && Number(form.daily) < Number(value)) {
              newError = { ...newError, daily: t('error.mustMoreThanCashMin') }
            }
            return { isPass: true, stop: false, newError }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      daily: [
        {
          func: createValidateNotEmpty('daily', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (form.cashMax !== '' && Number(value) < Number(form.cashMax)) {
              return { isPass: false, stop: true, newError: { daily: t('error.mustMoreThanCashMin') } }
            }
            return { isPass: true, stop: false, newError: { daily: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      dailyCount: [
        {
          func: createValidateNotEmpty('dailyCount', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<EditWithdrawalLimitFormType>
  }, [t])
  return (
    <FormStateProvider
      context={context}
      defaultValue={defaultValue}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
      onSubmit={onSubmit}
    >
      <Paper>
        <Box padding={4}>
          <Box
            marginBottom={2}
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {title}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormField<EditWithdrawalLimitFormType, TextFieldProps>
                  context={context}
                  component={TextField}
                  name="account"
                  label={t('common.playerAccount')}
                  placeholder={t('placeholder.inputPlayerAccount')}
                  fullWidth
                  required
                  disabled={disableAccount}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <FormField<EditWithdrawalLimitFormType, TextFieldProps>
                context={context}
                component={NumberInput}
                name="cashMax"
                label={t('common.cashMaxWithdraw')}
                placeholder={t('placeholder.inputCashMaxWithdraw')}
                fullWidth
                inputProps={cashInputProps}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormField<EditWithdrawalLimitFormType, TextFieldProps>
                context={context}
                component={NumberInput}
                name="daily"
                label={t('common.cashMaxWithdrawDaily')}
                placeholder={t('placeholder.inputCashMaxWithdrawDaily')}
                fullWidth
                inputProps={cashInputProps}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormField<EditWithdrawalLimitFormType, TextFieldProps>
                context={context}
                component={NumberInput}
                name="dailyCount"
                label={t('common.withdrawTimesDaily')}
                placeholder={t('placeholder.inputWithdrawTimesDaily')}
                fullWidth
                inputProps={cashInputProps}
                required
              />
            </Grid>
          </Grid>
          <Box paddingTop={2}>
            <RequiredText />
          </Box>
        </Box>
      </Paper>
      <Box marginTop={2}>
        <Grid container justify="flex-end" direction="row" spacing={2}>
          <Grid item>
            <Button className={classes.greyButton} onClick={onCancel}>{t('common.cancel')}</Button>
          </Grid>
          <Grid item>
            <FormSubmitButton
              context={context}
              component={Button}
              type="submit"
              className={classes.purpleGradualButton}
            >
              {okText}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(EditWithdrawalLimitForm)
