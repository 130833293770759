import GroupKey from '../../components/admin/navigation/GroupKey'
import RouteKey from '../../components/admin/route/RouteKey'
import { PermissionType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const menuPermissionMap: Array<{ group: GroupKey, groupName: TKeyType, route: RouteKey, routeName: TKeyType, permissions: PermissionType[] }> = [
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_ANNOUNCEMENT, routeName: 'page.announcement', permissions: [PermissionType.ANNOUNCEMENT_READ] },
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_AGENT_ANNOUNCEMENT, routeName: 'page.agentAnnouncement', permissions: [PermissionType.AGENT_ANNOUNCEMENT_READ] },
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_NOTIFICATION, routeName: 'page.notification', permissions: [PermissionType.NOTIFICATION_SETTING_READ] },
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_PERSONAL, routeName: 'page.announcementPersonal', permissions: [PermissionType.SEND_MESSAGE_READ] },
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_SMS, routeName: 'page.sms', permissions: [PermissionType.SMS_RECORD_READ] },
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_DOWNLOAD_LINK, routeName: 'page.downloadLink', permissions: [PermissionType.FORWARDER_DOWNLOAD_LINK_SETTING_READ] },
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_JOINT_VENTURE_SETTING, routeName: 'page.jointVenture', permissions: [PermissionType.JOINT_VENTURE_LINK_SETTING_READ] },
  { group: GroupKey.FORESTAGE, groupName: 'group.forestage', route: RouteKey.FORESTAGE_BANNER_NOTIFICATION, routeName: 'page.bannerNotification', permissions: [PermissionType.BANNER_NOTIFICATION_READ] },

  { group: GroupKey.EXTERNAL_GAME, groupName: 'group.externalGame', route: RouteKey.EXTERNAL_GAME, routeName: 'page.externalGame', permissions: [PermissionType.EXTERNAL_GAME_SETTING_READ] },
  { group: GroupKey.EXTERNAL_GAME, groupName: 'group.externalGame', route: RouteKey.EXTERNAL_GAME_FEEDBACK, routeName: 'page.feedbackSetting', permissions: [PermissionType.FEEDBACK_SETTING_READ] },
  { group: GroupKey.EXTERNAL_GAME, groupName: 'group.externalGame', route: RouteKey.EXTERNAL_GAME_CHANNEL, routeName: 'page.channel', permissions: [PermissionType.CHANNEL_SETTING_READ] },

  { group: GroupKey.ACCOUNT, groupName: 'group.account', route: RouteKey.ACCOUNT_EMPEROR, routeName: 'page.emperor', permissions: [PermissionType.EMPEROR_MANAGEMENT_READ] },

  { group: GroupKey.PLAYER, groupName: 'group.player', route: RouteKey.PLAYER_GAME_LOGIN_HISTORY, routeName: 'page.gameLoginHistory', permissions: [PermissionType.PLAYER_GAME_LOGIN_HISTORY_READ] },
  { group: GroupKey.PLAYER, groupName: 'group.player', route: RouteKey.PLAYER_JUMP_SHIP, routeName: 'page.playerJumpShip', permissions: [PermissionType.PLAYER_JUMP_SHIP_READ] },
  { group: GroupKey.PLAYER, groupName: 'group.player', route: RouteKey.PLAYER, routeName: 'page.player', permissions: [PermissionType.PLAYER_MANAGEMENT_READ] },
  { group: GroupKey.PLAYER, groupName: 'group.player', route: RouteKey.PLAYER_RANK, routeName: 'page.playerRank', permissions: [PermissionType.PLAYER_RANK_MANAGEMENT_READ] },
  { group: GroupKey.PLAYER, groupName: 'group.player', route: RouteKey.PLAYER_LAYER, routeName: 'page.playerLayer', permissions: [PermissionType.PLAYER_LAYER_MANAGEMENT_READ] },
  { group: GroupKey.PLAYER, groupName: 'group.player', route: RouteKey.PLAYER_RANK_EFFECTIVE_BET, routeName: 'page.rankEffectiveBet', permissions: [PermissionType.PLAYER_RANK_EFFECTIVE_BET_READ] },

  { group: GroupKey.ADMIN_REPORT, groupName: 'group.adminReport', route: RouteKey.PLAYER_REPORT_PROFIT, routeName: 'page.playerReportProfit', permissions: [PermissionType.PROFIT_RECORD_READ] },

  { group: GroupKey.PLAYER_REPORT, groupName: 'group.playerReport', route: RouteKey.PLAYER_REPORT_BET, routeName: 'page.betHistory', permissions: [PermissionType.BET_RECORD_READ] },
  { group: GroupKey.PLAYER_REPORT, groupName: 'group.playerReport', route: RouteKey.PLAYER_REPORT_TRANSFER, routeName: 'page.transferHistory', permissions: [PermissionType.TRANSFER_RECORD_READ] },

  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_PARAMETER, routeName: 'page.financeParameter', permissions: [PermissionType.FINANCE_SETTING_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_PIGGY_BANK, routeName: 'page.piggyBank', permissions: [PermissionType.PIGGY_BANK_MANAGEMENT_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_PIGGY_WECHAT, routeName: 'page.piggyWechat', permissions: [PermissionType.PIGGY_WECHAT_MANAGEMENT_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_PIGGY_ALIPAY, routeName: 'page.piggyAlipay', permissions: [PermissionType.PIGGY_ALIPAY_MANAGEMENT_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_BANK, routeName: 'page.bank', permissions: [PermissionType.BANK_SETTING_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_COURIER, routeName: 'page.courier', permissions: [PermissionType.FINANCE_FORWARDER_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_DEPOSIT_WAY, routeName: 'page.deposit', permissions: [PermissionType.THIRD_PARTY_PAYMENT_SETTING_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_WITHDRAWAL_WAY, routeName: 'page.withdrawal', permissions: [PermissionType.WITHDRAW_WAY_SETTING_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_BLACKLIST, routeName: 'page.blacklist', permissions: [PermissionType.DEBIT_CARD_BLACKLIST_READ] },
  { group: GroupKey.FINANCE, groupName: 'group.finance', route: RouteKey.FINANCE_INTERNAL_WITHDRAW, routeName: 'page.internalWithdraw', permissions: [PermissionType.INTERNAL_WITHDRAW_READ] },

  { group: GroupKey.DEPOSIT, groupName: 'group.deposit', route: RouteKey.DEPOSIT_CRAWLER, routeName: 'page.depositCrawler', permissions: [PermissionType.DEPOSIT_CRAWLER_READ] },
  { group: GroupKey.DEPOSIT, groupName: 'group.deposit', route: RouteKey.DEPOSIT_SLIP, routeName: 'page.depositSlip', permissions: [PermissionType.GOLDEN_DEPOSIT_MANAGEMENT_READ] },
  { group: GroupKey.DEPOSIT, groupName: 'group.deposit', route: RouteKey.DEPOSIT_THIRD_PARTY_SLIP, routeName: 'page.thirdPartyDepositSlip', permissions: [PermissionType.THIRD_PARTY_DEPOSIT_MANAGEMENT_READ] },

  { group: GroupKey.WITHDRAWAL, groupName: 'group.withdrawal', route: RouteKey.WITHDRAWAL_RC_SLIP, routeName: 'page.withdrawRCManage', permissions: [PermissionType.WITHDRAW_RC_SLIP_READ] },
  { group: GroupKey.WITHDRAWAL, groupName: 'group.withdrawal', route: RouteKey.WITHDRAWAL_FM_SLIP, routeName: 'page.withdrawFMManage', permissions: [PermissionType.WITHDRAW_FM_SLIP_READ] },
  { group: GroupKey.WITHDRAWAL, groupName: 'group.withdrawal', route: RouteKey.WITHDRAWAL_LIMIT, routeName: 'page.withdrawLimit', permissions: [PermissionType.WITHDRAW_LIMIT_SETTING_READ] },
  { group: GroupKey.WITHDRAWAL, groupName: 'group.withdrawal', route: RouteKey.WITHDRAWAL_AUTO, routeName: 'page.withdrawAuto', permissions: [PermissionType.AUTO_WITHDRAW_SETTING_READ] },
  { group: GroupKey.WITHDRAWAL, groupName: 'group.withdrawal', route: RouteKey.WITHDRAWAL_TIP, routeName: 'page.withdrawSetting', permissions: [PermissionType.WITHDRAW_TIP_SETTING_READ] },

  { group: GroupKey.MANUAL, groupName: 'group.manual', route: RouteKey.MANUAL_DEPOSIT, routeName: 'page.manualDeposit', permissions: [PermissionType.MANUAL_DEPOSIT_READ] },
  { group: GroupKey.MANUAL, groupName: 'group.manual', route: RouteKey.MANUAL_WITHDRAW, routeName: 'page.manualWithdraw', permissions: [PermissionType.MANUAL_WITHDRAW_READ] },
  { group: GroupKey.MANUAL, groupName: 'group.manual', route: RouteKey.MANUAL_EFFECTIVE_CASH_HISTORY, routeName: 'page.effectiveCashHistory', permissions: [PermissionType.EFFECTIVE_CASH_READ] },

  { group: GroupKey.ACTIVITY, groupName: 'group.activityManagement', route: RouteKey.ACTIVITY_MANAGEMENT, routeName: 'page.activityManagement', permissions: [PermissionType.ACTIVITY_MANAGEMENT_READ] },
  { group: GroupKey.ACTIVITY, groupName: 'group.activityManagement', route: RouteKey.ACTIVITY_APPLICATION, routeName: 'page.activityApplication', permissions: [PermissionType.ACTIVITY_APPLICATION_READ] },
  { group: GroupKey.ACTIVITY, groupName: 'group.activityManagement', route: RouteKey.ACTIVITY_APPLICATION, routeName: 'page.activityWallet', permissions: [PermissionType.ACTIVITY_WALLET_READ] },

  { group: GroupKey.SYSTEM, groupName: 'group.system', route: RouteKey.SYSTEM_PLATFORM_MAINTENANCE, routeName: 'page.platformMaintenance', permissions: [PermissionType.PLATFORM_MAINTENANCE_SETTING_READ] },
  { group: GroupKey.SYSTEM, groupName: 'group.system', route: RouteKey.SYSTEM_GOOGLE, routeName: 'page.systemGoogle', permissions: [PermissionType.SYS_GOOGLE_AUTH_READ] },
  { group: GroupKey.SYSTEM, groupName: 'group.system', route: RouteKey.SYSTEM_IP_WHITELIST, routeName: 'page.ipWhitelist', permissions: [PermissionType.IP_WHITELIST_READ] },

  { group: GroupKey.MANAGER, groupName: 'group.manager', route: RouteKey.MANAGER_ACCOUNT, routeName: 'page.accountManagement', permissions: [PermissionType.COMPANY_ACCOUNT_MANAGEMENT_READ] },
  { group: GroupKey.MANAGER, groupName: 'group.manager', route: RouteKey.ROLE_MANAGEMENT, routeName: 'page.roleManagement', permissions: [PermissionType.ROLE_GROUP_SETTING_READ] },

  { group: GroupKey.JOURNAL, groupName: 'group.journal', route: RouteKey.JOURNAL_EVENT, routeName: 'page.warningLog', permissions: [PermissionType.ALERT_RECORD_READ] },

  { group: GroupKey.COMPLAIN, groupName: 'group.complain', route: RouteKey.COMPLAIN, routeName: 'page.complain', permissions: [PermissionType.COMPLAIN_READ] }
]

export default menuPermissionMap
