import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Badge from '@material-ui/core/Badge'

interface PropTypes {
  icon: React.ComponentType<SvgIconProps>
  badgeContent: number
}

const IconWithBadge: React.FC<SvgIconProps & PropTypes> = (props) => {
  const { icon: Icon, badgeContent, ...rest } = props
  return (
    <Badge color="error" variant="dot" badgeContent={badgeContent}>
      <Icon {...rest} />
    </Badge>
  )
}

export default React.memo(IconWithBadge)
