import React, { useContext, useCallback, ChangeEvent } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { DialogPayloadContext } from '../../../views/admin/complain/ComplainPage'
import useT from '../../../i18ns/admin/useT'
import CustomThemeType from '../../../themes/admin/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  titleRoot: {
    paddingBottom: 0
  },
  mailTitle: {
    borderBottom: `solid 1px ${theme.palette.grey[200]}`
  },
  icon: {
    color: '#6E99E3',
    height: 48,
    width: 48
  },
  title: {
    color: theme.palette.common.black
  },
  account: {
    color: theme.palette.grey[400]
  },
  content: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  article: {
    minHeight: 150,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  textField: {
    minHeight: 150
  },
  reply: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1)
  }
}))

export interface Payload {
  showTextField: boolean
  showCancel: boolean
  okText: string
  onOK: (content: string) => void
  onCancel: () => void
  onChange: (value: string) => void
  open: boolean
  form: {
    title: string
    complain: string
    reply: string
    fromAccount: string
    toAccount: string
  }
}

const ComplainDialog: React.FC = () => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const [payload] = useContext(DialogPayloadContext)
  const {
    showTextField,
    showCancel,
    okText,
    onOK,
    onCancel,
    onChange,
    open,
    form
  } = payload
  const { t } = useT()
  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.detailMessage')}
      </Box>
      <DialogTitle className={classes.titleRoot}>
        <Box padding={3} paddingBottom={2} className={classes.mailTitle}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <AccountCircleIcon className={classes.icon} />
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h6" className={classes.title}>{form.title}</Typography>
                  <Typography className={classes.account}>{`${t('common.complainAccountWithColon')}${form.toAccount}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText className={classes.article}>
          {form.complain}
        </DialogContentText>
        {!showTextField && (
          <DialogContentText className={clsx(classes.article, classes.reply)}>
            {form.reply}
          </DialogContentText>
        )}
        {showTextField && (
          <TextField
            multiline
            fullWidth
            InputProps={{ classes: { input: classes.textField } }}
            value={form.reply}
            onChange={handleChange}
            variant="outlined"
            placeholder={t('placeholder.inputReplyContent')}
          />
        )}
        <Typography align="right" className={classes.account}>
          {`${t('common.replyAccountWithColon')}${form.fromAccount}`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box padding={3} paddingRight={5}>
          <Grid container direction="row" spacing={2}>
            {showCancel && (
              <Grid item>
                <Button
                  className={commonClasses.greyButton}
                  onClick={onCancel}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                className={commonClasses.purpleGradualButton}
                disabled={showTextField && form.reply.length === 0}
                onClick={useCallback(() => onOK(form.reply), [form.reply, onOK])}
              >
                {okText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ComplainDialog
