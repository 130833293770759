import React, { useCallback, useMemo } from 'react'
import useT from '../../i18ns/admin/useT'
import DropDown from '../default/form/DropDown'
import { GameCategoryType } from '@golden/gdk-admin'
import { useInternValue } from '../../utils/default/FormHook'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'

export interface PropTypes {
  value?: GameCategoryType | 'all'
  defaultValue?: GameCategoryType | 'all'
  onChange?: (value: GameCategoryType | 'all') => void
  noAll?: boolean
  required?: boolean
  fullWidth?: boolean
}

const GameCategoryInput: React.FC<PropTypes> = React.memo((props) => {
  const { value = 'all', defaultValue, onChange, noAll = false, required = false, fullWidth = false } = props
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue<PropTypes['value']>(defaultValue ?? value, value)
  const categories = useGDKStore.platform.categories()
  const options = useMemo(() => [
    ...(noAll ? [] : [{ name: t('common.all'), value: 'all' }]),
    ...categories.map((category) => ({ value: category.instance.id, name: category.instance.name }))
  ], [categories, noAll, t])
  return (
    <DropDown
      value={internValue}
      onChange={useCallback((event) => {
        const value = event.target.value
        setInternValue(value)
        if (onChange) onChange(value)
      }, [onChange, setInternValue])}
      label={t('common.gameType')}
      options={options}
      fullWidth={fullWidth}
      required={required}
    />
  )
})

export default React.memo(GameCategoryInput)
