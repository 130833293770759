import { utcToZonedTime } from 'date-fns-tz'
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addDays, subDays, subWeeks, subMonths, addMonths, addWeeks, isBefore, isAfter, isEqual } from 'date-fns'
import { DateInputValue } from '../../components/default/form/DateInput'

export function setToday (): DateInputValue {
  return { start: startOfDay(new Date()), end: endOfDay(new Date()) }
}

export function setYesterday (): DateInputValue {
  const yesterday = subDays(new Date(), 1)
  return { start: startOfDay(yesterday), end: endOfDay(yesterday) }
}

export function setTwoDaysLater (): DateInputValue {
  const twoDaysLater = endOfDay(addDays(new Date(), 2))
  return { start: startOfDay(new Date()), end: twoDaysLater }
}

export function setLastSevenDays (): DateInputValue {
  return { start: startOfDay(subDays(new Date(), 6)), end: endOfDay(new Date()) }
}

export function setLastFourteenDays (): DateInputValue {
  return { start: startOfDay(subDays(new Date(), 13)), end: endOfDay(new Date()) }
}

export function setThisWeek (): DateInputValue {
  return { start: startOfWeek(new Date(), { weekStartsOn: 1 }), end: endOfDay(new Date()) }
}

export function setLastWeek (): DateInputValue {
  const oneDayInLastWeek = subWeeks(new Date(), 1)
  return { start: startOfWeek(oneDayInLastWeek, { weekStartsOn: 1 }), end: endOfWeek(oneDayInLastWeek, { weekStartsOn: 1 }) }
}

export function setThisMonth (): DateInputValue {
  return { start: startOfMonth(new Date()), end: endOfDay(new Date()) }
}

export function setThisMonthToYesterday (): DateInputValue {
  return { start: startOfMonth(new Date()), end: endOfDay(subDays(new Date(), 1)) }
}

export function setLastMonth (): DateInputValue {
  const oneDayInLastMonth = subMonths(new Date(), 1)
  return { start: startOfMonth(oneDayInLastMonth), end: endOfMonth(oneDayInLastMonth) }
}

export function createShouldDisableDate (startTime: Date | null, endTime: Date | null, limitDate: Date, startDate?: Date) {
  const newStartDate: Date = startDate ?? new Date()
  const shouldDisableStartDate = (day: Date | null): boolean => {
    if (day === null) return true
    if (isBefore(day, limitDate)) return true
    if (endTime === null) return isAfter(day, endOfDay(newStartDate))
    return isAfter(day, endOfDay(endTime))
  }
  const shouldDisableEndDate = (day: Date | null): boolean => {
    if (day === null) return true
    if (isAfter(day, endOfDay(newStartDate))) return true
    if (startTime === null) return isBefore(day, limitDate)
    return isBefore(day, startOfDay(startTime))
  }

  return {
    shouldDisableStartDate,
    shouldDisableEndDate
  }
}

export function toTimezonedDate (date: Date) {
  return utcToZonedTime(date, '+0800')
}

export function getMonthArray (start: Date, end: Date): Date[] {
  const monthStart = startOfMonth(start)
  const monthEnd = startOfMonth(end)
  let months: Date[] = []
  for (let monthCursor = monthStart; isBefore(monthCursor, monthEnd) || isEqual(monthCursor, monthEnd); monthCursor = addMonths(monthCursor, 1)) {
    months = months.concat(monthCursor)
  }
  return months
}

export function getWeekArray (start: Date, end: Date): Date[] {
  const weekStart = startOfWeek(start, { weekStartsOn: 1 })
  const weekEnd = startOfWeek(end, { weekStartsOn: 1 })
  let weeks: Date[] = []
  for (let weekCursor = weekStart; isBefore(weekCursor, weekEnd) || isEqual(weekCursor, weekEnd); weekCursor = addWeeks(weekCursor, 1)) {
    weeks = weeks.concat(weekCursor)
  }
  return weeks
}

export function getDayArray (start: Date, end: Date): Date[] {
  const dayStart = startOfDay(start)
  const dayEnd = startOfDay(end)
  let days: Date[] = []
  for (let dayCursor = dayStart; isBefore(dayCursor, dayEnd) || isEqual(dayCursor, dayEnd); dayCursor = addDays(dayCursor, 1)) {
    days = days.concat(dayCursor)
  }
  return days
}
