import RegionType from '../../default/navigation/RegionType'
import GroupKey from './GroupKey'

export enum RegionKey {
  MY_REGION = 'myRegion',
  FUNCTIONS = 'functions'
}

export const regionOrder: RegionKey[] = [
  RegionKey.MY_REGION,
  RegionKey.FUNCTIONS
]

const region: {
  [key in RegionKey]: RegionType<RegionKey, GroupKey>
} = {
  [RegionKey.MY_REGION]: {
    key: RegionKey.MY_REGION,
    noDivider: false,
    groups: [
      GroupKey.MY_DATA
    ]
  },
  [RegionKey.FUNCTIONS]: {
    key: RegionKey.FUNCTIONS,
    noDivider: true,
    groups: [
      GroupKey.FORESTAGE,
      GroupKey.EXTERNAL_GAME,
      GroupKey.ACCOUNT,
      GroupKey.PLAYER,
      GroupKey.ADMIN_REPORT,
      GroupKey.PLAYER_REPORT,
      GroupKey.FINANCE,
      GroupKey.DEPOSIT,
      GroupKey.WITHDRAWAL,
      GroupKey.MANUAL,
      GroupKey.ACTIVITY,
      GroupKey.CUSTOMER_SERVICE,
      GroupKey.SYSTEM,
      GroupKey.MANAGER,
      GroupKey.JOURNAL,
      GroupKey.COMPLAIN
    ]
  }
}

export default region
