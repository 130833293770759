import React, { MouseEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Button, { ButtonClassKey } from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CustomThemeType from '../../../themes/default/CustomThemeType'
import useT from '../../../i18ns/admin/useT'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  icon: {
    fontSize: 40,
    color: (props: { variant: string | undefined }) => {
      switch (props.variant) {
        case 'success':
          return theme.palette.primary.main
        case 'warning':
          return theme.palette.secondary.main
        case 'error':
          return theme.palette.error.main
        case 'info':
          return theme.custom.palette.blue.main
        default:
          return theme.palette.primary.main
      }
    }
  },
  text: {
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    lineHeight: 1.5
  }
}))

export type Variant = 'success' | 'warning' | 'error' | 'info'

export interface PropTypes {
  open: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  variant?: Variant
  message?: string | React.ReactElement
  notUseTypo?: boolean
  showIcon?: boolean
  showOK?: boolean
  okText?: string
  showCancel?: boolean
  cancelText?: string
  disabledOK?: boolean
  loading?: boolean
  onOK?: (event: MouseEvent<HTMLButtonElement>) => void
  onCancel?: (event: MouseEvent<HTMLButtonElement>) => void
  okButtonClasses?: Partial<Record<ButtonClassKey, string>>
  cancelButtonClasses?: Partial<Record<ButtonClassKey, string>>
}

const CoreDialog: React.FC<PropTypes> = (props) => {
  const {
    open,
    message,
    variant,
    notUseTypo,
    showIcon,
    showOK,
    okText,
    showCancel,
    cancelText,
    disabledOK,
    loading,
    maxWidth = 'sm',
    onOK,
    onCancel,
    okButtonClasses,
    cancelButtonClasses
  } = props
  const classes = useStyles({ variant })
  const { t } = useT()
  const cases = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
  }
  const Icon = variant === undefined ? InfoIcon : cases[variant]
  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth}>
      <Box paddingY={2}>
        <DialogContent>
          <Box paddingY={8}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              {showIcon && (
                <Box paddingY={2.5}>
                  <Icon className={classes.icon} />
                </Box>
              )}
              {notUseTypo
                ? (
                <React.Fragment>{message}</React.Fragment>
                  )
                : (
                <Typography className={classes.text}>{message}</Typography>
                  )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            {showCancel && (
              <Button onClick={onCancel} classes={cancelButtonClasses}>
                {cancelText ?? t('common.cancel')}
              </Button>
            )}
            {showOK && (
              <Button onClick={onOK} classes={okButtonClasses} disabled={disabledOK ?? loading}>
                {loading
                  ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={24} />
                  </Box>
                    )
                  : (okText ?? t('common.confirm'))}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default React.memo(CoreDialog)
