import React, { useContext, useCallback, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { Complain, Branch } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { DialogPayloadContext } from '../../../views/admin/complain/ComplainPage'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

interface PropTypes {
  id: number
  item: Complain
  reload: () => void
  writable: boolean
}

const ButtonsBase: React.FC<{
  isFinished: boolean
  writable: boolean
  handleReply: () => void
  handleDetail: () => void
}> = React.memo((props) => {
  const {
    isFinished,
    writable,
    handleReply,
    handleDetail
  } = props
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container direction="column" spacing={1} justify="center">
      <Grid item>
        <Button
          onClick={handleDetail}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.detailMessage')}
        </Button>
      </Grid>
      {
        writable && !isFinished && (
          <Grid item>
            <Button
              onClick={handleReply}
              size="small"
              className={classes.blueGradualOutlineButton}
            >
              {t('common.replyContent')}
            </Button>
          </Grid>
        )
      }
    </Grid>
  )
})

const ComplainButtons: React.FC<PropTypes> = (props) => {
  const { id, item, reload, writable } = props
  const { t } = useT()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const globalDialog = useGlobalDialog()
  const [, setPayload] = useContext(DialogPayloadContext)
  const handleClose = useCallback(() => setPayload((payload) => ({
    ...payload,
    open: false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [])
  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: `replyComplain-${item.id}`,
    globalDialog,
    getChangeDialogConfig: useCallback(() => {
      handleClose()
      return createGlobalDialogConfig({
        showIcon: false,
        message: t('dialog.confirmReplyComplain')
      })
    }, [handleClose, t]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.replyComplainSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest: (form: string) => form,
    gdkFunc: (content: string) => gdk.complain.replyComplain(id, { content }),
    gdkFuncDependencies: [gdk, id],
    onSuccess: () => {
      gdk.trunk.trigger([Branch.ME])
      reload()
    },
    onSuccessDependencies: [gdk, reload]
  })
  const handleChange = useCallback((value: string) => setPayload((payload) => ({
    ...payload,
    form: {
      ...payload.form,
      reply: value
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [])
  const meAccount = useMemo(() => me?.account ?? '', [me])
  const form = useMemo(() => ({
    title: item.title,
    complain: item.complain_content,
    reply: item.is_finished ? item.reply_content : '',
    fromAccount: item.is_finished ? item.reply_by : meAccount,
    toAccount: item.account
  }), [item.account, item.complain_content, item.title, item.is_finished, item.reply_by, item.reply_content, meAccount])
  const handleDetail = useCallback(() => setPayload((payload) => ({
    ...payload,
    showTextField: false,
    showCancel: false,
    okText: t('common.close'),
    onOK: handleClose,
    open: true,
    form
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [form])
  const handleReply = useCallback(() => setPayload((payload) => ({
    ...payload,
    showTextField: true,
    showCancel: true,
    okText: t('common.reply'),
    onOK: handleSubmit,
    onCancel: handleClose,
    onChange: handleChange,
    open: true,
    form
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [form])
  return (
    <ButtonsBase
      isFinished={item.is_finished}
      writable={writable}
      handleDetail={handleDetail}
      handleReply={handleReply}
    />
  )
}

export default ComplainButtons
