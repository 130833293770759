import RouteKey from '../route/RouteKey'
import { LinkKey } from './link'
import TKeyType from '../../../i18ns/admin/TKeyType'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ComplainIcon from './ComplainIcon'
import EventIcon from './EventIcon'
import WithdrawIcon from './WithdrawIcon'
import PeopleIcon from './PeopleIcon'

export interface TopNavType {
  key: TopNavKey
  title: TKeyType
  icon: React.ComponentType<SvgIconProps>
  item: RouteKey | LinkKey | RouteKey[]
}

export enum TopNavKey {
  PEOPLE_COUNT = 'peopleCount',
  EVENT_LOG = 'eventLog',
  FEEDBACK = 'feedback',
  PAYMENT = 'payment',
  DISPENSING = 'dispensing',
  DEPOSIT = 'deposit'
}

export const topNavOrder: TopNavKey[] = [
  TopNavKey.EVENT_LOG,
  TopNavKey.FEEDBACK,
  TopNavKey.PAYMENT,
  TopNavKey.DISPENSING,
  TopNavKey.DEPOSIT,
  TopNavKey.PEOPLE_COUNT
]

const topNav: {
  [key in TopNavKey]: TopNavType
} = {
  [TopNavKey.PEOPLE_COUNT]: {
    key: TopNavKey.PEOPLE_COUNT,
    title: 'topNav.peopleCount',
    icon: PeopleIcon,
    item: [
      RouteKey.ADMIN_REPORT_NEW_PEOPLE_STATISTIC
    ]
  },
  [TopNavKey.EVENT_LOG]: {
    key: TopNavKey.EVENT_LOG,
    title: 'topNav.warningLog',
    icon: EventIcon,
    item: RouteKey.JOURNAL_EVENT
  },
  [TopNavKey.FEEDBACK]: {
    key: TopNavKey.FEEDBACK,
    title: 'topNav.feedback',
    icon: ComplainIcon,
    item: RouteKey.COMPLAIN
  },
  [TopNavKey.PAYMENT]: {
    key: TopNavKey.PAYMENT,
    title: 'topNav.payment',
    icon: AccountBalanceIcon,
    item: RouteKey.HOME
  },
  [TopNavKey.DISPENSING]: {
    key: TopNavKey.DISPENSING,
    title: 'topNav.dispensing',
    icon: WithdrawIcon,
    item: RouteKey.WITHDRAWAL_FM_SLIP
  },
  [TopNavKey.DEPOSIT]: {
    key: TopNavKey.DEPOSIT,
    title: 'topNav.deposit',
    icon: SaveAltIcon,
    item: [
      RouteKey.DEPOSIT_SLIP,
      RouteKey.DEPOSIT_THIRD_PARTY_SLIP
    ]
  }
}

export default topNav
