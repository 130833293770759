import React, { useMemo, useContext, useCallback, createContext, useState } from 'react'
import { PaginationReq, PlayerProfitQuery, GameCategoryType, GameType, RecordSearchTimeType, PermissionType, SpecifyLeague } from '@golden/gdk-admin'
import { endOfDay, startOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, guaranteeBeOneOf, pipe, parseInt, acceptUndefined, parseArray, guaranteeBeKey, getValueFromValue, getTimeFromDateInputValue, getValueFromChangeEvent, convertEmptyToUndefined, getValueFromCheckboxEvent, parseBoolean } from '../../../../utils/default/FormHelper'
import DateInputBase, { DateInputValue } from '../../../default/form/DateInput'
import FormStateProvider from '../../../default/form/FormStateProvider'
import FormField from '../../../default/form/FormField'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../../utils/default/FormHook'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth, setLastMonth } from '../../../../utils/default/TimeHelper'
import allRoute from '../../route/route'
import recordSearchTimeName from '../../../../constants/admin/recordSearchTimeName'
import { createValidateNotEmpty } from '../../../../utils/default/Validator'
import RequiredText from '../../../default/form/RequiredText'
import { Link } from 'react-router-dom'
import GameInput, { PropTypes as GameInputProps } from '../../GameInput'
import { enumValues, omitBy, isUndefined } from '@golden/utils'
import SearchDropDown, { PropTypes as SearchDropDownProps } from './SearchDropDown'
import DropDown, { PropTypes as DropDownProps } from '../../../default/form/DropDown'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import SportLeagueDialog from '../SportLeagueDialog'
import SpecifyLeagueDialogButton from '../SpecifyLeagueDialogButton'

export type PlayerReportProfitRequest = PaginationReq & PlayerProfitQuery
export type PlayerReportProfitRequestWithJson = Omit<PlayerReportProfitRequest, 'specify_league'> & { specify_league?: string }

export const searchToRequest: SearchToRequestFunc<PlayerReportProfitRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    search_type: acceptUndefined(search.search_type, pipe(
      guaranteeNotUndefined,
      (value) => guaranteeBeKey(value, Object.keys(recordSearchTimeName))
    )),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.end_at),
    game_category: acceptUndefined(search.game_category, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameCategoryType)),
      parseInt
    )),
    game_id: acceptUndefined(search.game_id, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameType)),
      parseInt
    )),
    channel_id: acceptUndefined(search.channel_id, pipe(
      parseArray,
      (values) => values.map((item: string) => Number(item))
    )),
    lottery_channel_id: acceptUndefined(search.channel_id, pipe(
      parseArray,
      (values) => values.map((item: string) => Number(item))
    )),
    filter_zero_cash: acceptUndefined(search.filter_zero_cash, pipe(
      guaranteeNotUndefined,
      (value: string) => Boolean(value)
    )),
    is_activity_excluding: acceptUndefined(search.is_activity_excluding, parseBoolean),
    specify_league: acceptUndefined(search.specify_league, pipe(
      (value) => JSON.parse(value)
    ))
  } as PlayerReportProfitRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as PlayerReportProfitRequest
}

export interface PlayerReportProfitFormType {
  search_type: RecordSearchTimeType
  time: DateInputValue
  account: string
  game: {
    game_category: GameCategoryType | 'all'
    game_id: GameType | 'noGame' | 'all'
    channel_id: number[]
    lottery_channel_id: number[]
  }
  filter_zero_cash: boolean
  is_activity_excluding: boolean | '--'
  specify_league: SpecifyLeague[]
}

export const initialForm: InitialFormFunc<PlayerReportProfitFormType> = (defaultForm) => ({
  search_type: RecordSearchTimeType.SETTLED_AT,
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  account: '',
  game: {
    game_category: 'all',
    game_id: 'all',
    channel_id: [],
    lottery_channel_id: [-1]
  },
  filter_zero_cash: false,
  is_activity_excluding: '--',
  specify_league: [],
  ...defaultForm
})

export const getValueFromEvent: ValueGetter<PlayerReportProfitFormType> = {
  search_type: getValueFromValue,
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  game: getValueFromValue,
  filter_zero_cash: getValueFromCheckboxEvent,
  is_activity_excluding: getValueFromChangeEvent,
  specify_league: getValueFromValue
}

const getChangedForm: ChangedFormGetter<PlayerReportProfitFormType> = {
  game: (value, form) => {
    if (value.game_category !== form.game.game_category) {
      return initialForm({ ...form, game: value, specify_league: [] })
    }
    return { ...form, game: value }
  }
}

export const formToRequest = (form: PlayerReportProfitFormType): Omit<PlayerReportProfitRequestWithJson, 'report_type'> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const channel_id = [GameType.VR_LOTTERY, GameType.SGWIN_LOTTERY].includes(form.game.game_id as GameType)
    ? form.game.lottery_channel_id[0] !== -1 ? form.game.lottery_channel_id[0] : undefined
    : form.game.channel_id.length > 0 ? form.game.channel_id : undefined
  const converted = {
    search_type: form.search_type,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: convertEmptyToUndefined(form.account),
    game_category: form.game.game_category === 'all' ? undefined : form.game.game_category,
    game_id: form.game.game_id === 'all' || form.game.game_id === 'noGame' ? undefined : form.game.game_id,
    filter_zero_cash: form.filter_zero_cash,
    channel_id,
    page: 1,
    is_activity_excluding: form.is_activity_excluding === '--' ? undefined : form.is_activity_excluding,
    specify_league: form.specify_league.length ? JSON.stringify(form.specify_league) : undefined
  } as Omit<PlayerReportProfitRequestWithJson, 'report_type'>
  const omited = omitBy(converted, isUndefined)
  return omited as Omit<PlayerReportProfitRequestWithJson, 'report_type'>
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const TimeTypeDropDown: React.FC = React.memo(() => (
  <FormField<PlayerReportProfitFormType, SearchDropDownProps>
    context={FormContext}
    component={SearchDropDown}
    name="search_type"
  />
))

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={TimeTypeDropDown}
    />
  )
})

const PlayerReportProfitForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const writableExport = useChecker([PermissionType.PROFIT_RECORD_EXPORT])
  const request = useRequestFromSearch({ searchToRequest })
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        game: {
          game_category: request.game_category ?? 'all',
          game_id: request.game_id ?? 'all',
          channel_id: request.channel_id ?? [],
          lottery_channel_id: request.channel_id ?? [-1]
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.playerReportProfit.encodePath
  })

  const validation = useMemo(() => ({
    search_type: [],
    time: [],
    account: [
      {
        func: createValidateNotEmpty('account', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    game: [],
    channel_id: [],
    unique_channel_id: [],
    filter_zero_cash: [],
    is_activity_excluding: [],
    specify_league: []
  } as FormValidation<PlayerReportProfitFormType>), [t])

  const activityExcludingTypeOptions = useMemo(() => ([
    {
      name: t('common.noLimit'),
      value: '--'
    },
    {
      name: t('common.notExclude'),
      value: false
    },
    {
      name: t('common.exclude'),
      value: true
    }
  ]), [t])

  return (
    <Paper>
      <Box
        paddingY={1.25}
        paddingX={2}
        className={classes.pinkTitleBar}
      >
        <Typography variant="h5">
          {t('page.playerReportProfit')}
        </Typography>
      </Box>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <FormStateProvider
            context={FormContext}
            defaultValue={defaultForm}
            onSubmit={handleSubmit}
            validation={validation}
            getValueFromEvent={getValueFromEvent}
            getChangedForm={getChangedForm}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <FormField<PlayerReportProfitFormType, GameInputProps>
                  name="game"
                  context={FormContext}
                  component={GameInput}
                  FrontComponent={(
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<PlayerReportProfitFormType, TextFieldProps>
                        context={FormContext}
                        component={TextField}
                        name="account"
                        label={t('common.playerAccount')}
                        placeholder={t('placeholder.inputPlayerAccount')}
                        fullWidth
                        required
                      />
                    </Grid>
                  )}
                  EndComponent={(<>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormField<PlayerReportProfitFormType, DropDownProps>
                        context={FormContext}
                        component={DropDown}
                        name="is_activity_excluding"
                        options={activityExcludingTypeOptions}
                        label={t('common.searchingEffectiveCash')}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <SpecifyLeagueDialogButton<PlayerReportProfitFormType>
                        setOpen={setOpenDialog}
                        context={FormContext}
                      />
                    </Grid>
                  </>)}
                />
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end" spacing={2}>
                  { writableExport && (<Grid item>
                    <MuiButton
                      component={Link}
                      to={allRoute.playerReportProfitExport.encodePath({ search: {}, param: {} })}
                      className={classes.purpleGradualButton}
                    >
                      {t('common.batchExport')}
                    </MuiButton>
                  </Grid>) }
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <SportLeagueDialog<PlayerReportProfitFormType>
              open={openDialog}
              onClose={() => { setOpenDialog(false) }}
              context={FormContext}
            />
          </FormStateProvider>
        </LoadingAndErrorFrame>
      </Box>
    </Paper>
  )
}

export default React.memo(PlayerReportProfitForm)
