import FaceIcon from '@material-ui/icons/Face'
import DashboardIcon from '@material-ui/icons/Dashboard'
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import TableChartIcon from '@material-ui/icons/TableChart'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SettingsIcon from '@material-ui/icons/Settings'
import SmsIcon from '@material-ui/icons/Sms'
import ListIcon from '@material-ui/icons/List'
import EditIcon from '@material-ui/icons/Edit'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import ActivityIcon from './ActivityIcon'
import GroupKey from './GroupKey'
import GroupType from '../../default/navigation/GroupType'
import RouteKey from '../route/RouteKey'
import { LinkKey } from './link'
import TKeyType from '../../../i18ns/admin/TKeyType'

const group: {
  [key in GroupKey]: GroupType<RouteKey, GroupKey, LinkKey, TKeyType>
} = {
  [GroupKey.MY_DATA]: {
    key: GroupKey.MY_DATA,
    icon: FaceIcon,
    title: 'group.myData',
    subItem: [
      RouteKey.GREETING,
      RouteKey.BASE_DATA,
      RouteKey.LOGOUT
    ]
  },
  [GroupKey.FORESTAGE]: {
    key: GroupKey.FORESTAGE,
    icon: DashboardIcon,
    title: 'group.forestage',
    subItem: [
      RouteKey.FORESTAGE_ANNOUNCEMENT,
      RouteKey.FORESTAGE_AGENT_ANNOUNCEMENT,
      RouteKey.FORESTAGE_NOTIFICATION,
      RouteKey.FORESTAGE_PERSONAL,
      RouteKey.FORESTAGE_SMS,
      RouteKey.FORESTAGE_DOWNLOAD_LINK,
      RouteKey.FORESTAGE_JOINT_VENTURE_SETTING,
      RouteKey.FORESTAGE_BANNER_NOTIFICATION
    ]
  },
  [GroupKey.EXTERNAL_GAME]: {
    key: GroupKey.EXTERNAL_GAME,
    icon: VideogameAssetIcon,
    title: 'group.externalGame',
    subItem: [
      RouteKey.EXTERNAL_GAME,
      RouteKey.EXTERNAL_GAME_FEEDBACK,
      RouteKey.EXTERNAL_GAME_CHANNEL
    ]
  },
  [GroupKey.ACCOUNT]: {
    key: GroupKey.ACCOUNT,
    icon: AccountCircleIcon,
    title: 'group.account',
    subItem: [
      RouteKey.ACCOUNT_EMPEROR,
      RouteKey.ACCOUNT_AGENT_WALLET,
      RouteKey.ACCOUNT_AGENT_OPERATION_LOG
    ]
  },
  [GroupKey.ADMIN_REPORT]: {
    key: GroupKey.ADMIN_REPORT,
    icon: TableChartIcon,
    title: 'group.adminReport',
    subItem: [
      RouteKey.ADMIN_REPORT_PROFIT,
      RouteKey.PLAYER_REPORT_PROFIT,
      RouteKey.ADMIN_REPORT_DEPOSIT_STATISTIC,
      RouteKey.ADMIN_REPORT_NEW_PEOPLE_STATISTIC,
      RouteKey.ADMIN_REPORT_DEVICE_STATISTIC,
      RouteKey.ADMIN_REPORT_COMPANY_STATISTIC,
      RouteKey.ADMIN_REPORT_IN_AND_OUT,
      RouteKey.ADMIN_REPORT_PLATFORM_FEE,
      RouteKey.ADMIN_REPORT_PLAYER_LEADERBOARD
    ]
  },
  [GroupKey.PLAYER_REPORT]: {
    key: GroupKey.PLAYER_REPORT,
    icon: EqualizerIcon,
    title: 'group.playerReport',
    subItem: [
      RouteKey.PLAYER_REPORT_BET,
      RouteKey.PLAYER_REPORT_FEEDBACK_HISTORY,
      RouteKey.PLAYER_REPORT_TRANSFER,
      RouteKey.PLAYER_REPORT_CASH,
      RouteKey.PLAYER_REPORT_CASH_ACCOUNTING,
      RouteKey.PLAYER_REPORT_WINLOSS_ANALYZE
    ]
  },
  [GroupKey.FINANCE]: {
    key: GroupKey.FINANCE,
    icon: AttachMoneyIcon,
    title: 'group.finance',
    subItem: [
      RouteKey.FINANCE_PARAMETER,
      RouteKey.FINANCE_PIGGY_BANK,
      RouteKey.FINANCE_PIGGY_WECHAT,
      RouteKey.FINANCE_PIGGY_ALIPAY,
      RouteKey.FINANCE_BANK,
      RouteKey.FINANCE_COURIER,
      RouteKey.FINANCE_DEPOSIT_WAY,
      RouteKey.FINANCE_WITHDRAWAL_WAY,
      RouteKey.FINANCE_BLACKLIST,
      RouteKey.FINANCE_INTERNAL_WITHDRAW
    ]
  },
  [GroupKey.DEPOSIT]: {
    key: GroupKey.DEPOSIT,
    icon: SaveAltIcon,
    title: 'group.deposit',
    subItem: [
      RouteKey.DEPOSIT_CRAWLER,
      RouteKey.DEPOSIT_SLIP,
      RouteKey.DEPOSIT_THIRD_PARTY_SLIP,
      RouteKey.DEPOSIT_ERROR
    ]
  },
  [GroupKey.WITHDRAWAL]: {
    key: GroupKey.WITHDRAWAL,
    icon: UnarchiveIcon,
    title: 'group.withdrawal',
    subItem: [
      RouteKey.WITHDRAWAL_RC_SLIP,
      RouteKey.WITHDRAWAL_FM_SLIP,
      RouteKey.WITHDRAWAL_LIMIT,
      RouteKey.WITHDRAWAL_AUTO,
      RouteKey.WITHDRAWAL_TIP
    ]
  },
  [GroupKey.MANUAL]: {
    key: GroupKey.MANUAL,
    icon: AccountBalanceIcon,
    title: 'group.manual',
    subItem: [
      RouteKey.MANUAL_DEPOSIT,
      RouteKey.MANUAL_WITHDRAW,
      RouteKey.MANUAL_EFFECTIVE_CASH_HISTORY
    ]
  },
  [GroupKey.ACTIVITY]: {
    key: GroupKey.ACTIVITY,
    icon: ActivityIcon,
    title: 'group.activityManagement',
    subItem: [
      RouteKey.ACTIVITY_MANAGEMENT,
      RouteKey.ACTIVITY_RECORD,
      RouteKey.ACTIVITY_RECORD_DETAIL,
      RouteKey.ACTIVITY_APPLICATION,
      RouteKey.ACTIVITY_WALLET
    ]
  },
  [GroupKey.CUSTOMER_SERVICE]: {
    key: GroupKey.CUSTOMER_SERVICE,
    icon: SmsIcon,
    title: 'group.customerService',
    subItem: LinkKey.BATTLE_FIELD
  },
  [GroupKey.SYSTEM]: {
    key: GroupKey.SYSTEM,
    icon: SettingsIcon,
    title: 'group.system',
    subItem: [
      RouteKey.SYSTEM_PLATFORM_MAINTENANCE,
      RouteKey.SYSTEM_GOOGLE,
      RouteKey.SYSTEM_IP_WHITELIST
    ]
  },
  [GroupKey.PLAYER]: {
    key: GroupKey.PLAYER,
    icon: AccountCircleIcon,
    title: 'group.player',
    subItem: [
      RouteKey.PLAYER_IP,
      RouteKey.PLAYER_GAME_LOGIN_HISTORY,
      RouteKey.PLAYER_IP_MONITORING,
      RouteKey.PLAYER_JUMP_SHIP,
      RouteKey.PLAYER,
      RouteKey.PLAYER_RANK,
      RouteKey.PLAYER_LAYER,
      RouteKey.PLAYER_RANK_EFFECTIVE_BET,
      RouteKey.PLAYER_OPERATION_LOGS
    ]
  },
  [GroupKey.MANAGER]: {
    key: GroupKey.MANAGER,
    icon: ListIcon,
    title: 'group.manager',
    subItem: [
      RouteKey.MANAGER_ACCOUNT,
      RouteKey.ROLE_MANAGEMENT,
      RouteKey.MANAGER_LOGIN_HISTORY
    ]
  },
  [GroupKey.JOURNAL]: {
    key: GroupKey.JOURNAL,
    icon: ListIcon,
    title: 'group.journal',
    subItem: [
      RouteKey.JOURNAL_OPERATION,
      RouteKey.JOURNAL_EVENT
    ]
  },
  [GroupKey.COMPLAIN]: {
    key: GroupKey.COMPLAIN,
    icon: EditIcon,
    title: 'group.complain',
    subItem: [
      RouteKey.COMPLAIN
    ]
  }
}

export default group
