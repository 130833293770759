import React, { useState, useMemo } from 'react'
import {
  PaginationRes,
  ActivityWalletItem,
  ActivityWalletLimitType
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from '../../activity/wallet/ActivityWalletForm'
import CoreTable from '../../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import allRoute from '../../route/route'
import DateTime from '../../../default/present/DateTime'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { getCategoryShortName, getGameName, getWalletNameByWalletId } from '../../../../utils/default/PlatformHelper'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import activityWalletStatusColor from '../../../../constants/admin/activityWalletStatusColor'
import activityWalletStatusTypeName from '../../../../constants/admin/activityWalletStatusName'

interface RowType {
  id: string
  account: string
  createdAt: React.ReactElement
  activitySerial: string
  title: string
  platform: string
  multiple: string
  cash: string
  status: React.ReactElement
  transferTo: string
  transferSerial: React.ReactElement
  updatedAt: React.ReactElement
}

const ActivityWalletTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const platforms = useGDKStore.platform.platforms()
  const games = useGDKStore.platform.games()
  const storeCategories = useGDKStore.platform.categories()
  const globalDialog = useGlobalDialog()
  const [list, setList] = useState<PaginationRes<ActivityWalletItem[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.activity.getActivityWalletList(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityWalletItem[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])

  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        account: item.account,
        createdAt: (<DateTime time={item.created_at} />),
        activitySerial: item.serial_number,
        title: item.title,
        platform: item.limit_type === ActivityWalletLimitType.GAME_CATEGORY ? item.limit_ids.map((id) => getCategoryShortName(id, storeCategories)).join('\n') : item.limit_ids.map((id) => getGameName(id, games)).join('\n'),
        multiple: `${item.multiple}`,
        cash: formatMoney(item.cash),
        status: (<span style={{ color: activityWalletStatusColor[item.status] }}>
          {t(activityWalletStatusTypeName[item.status])}
        </span>),
        transferTo: item.to === null ? '--' : getWalletNameByWalletId(item.to, platforms),
        transferSerial: item.transfer_logs.length === 0
          ? (<span>--</span>)
          : (
              item.transfer_logs.length === 1
                ? (<span>{ item.transfer_logs[0] }</span>)
                : (
                  <span
                    className={classes.anchor}
                    onClick={() => {
                      globalDialog.setConfig(createGlobalDialogConfig({
                        showIcon: false,
                        showCancel: false,
                        message: item.transfer_logs.join('\n')
                      }))
                      globalDialog.setOpen({
                        id: `transferSerial-${item.id}`,
                        value: true,
                        isOK: false
                      })
                    }}
                  >
                    {item.transfer_logs.length} {t('common.amountShort')}
                  </span>
                  )
            ),
        updatedAt: (<DateTime time={item.updated_at} />)
      } as RowType
    })
  }, [list.data, classes.anchor, games, storeCategories, platforms, t, globalDialog])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        createdAt: {
          label: t('common.sendDate'),
          value: 'createdAt',
          align: 'center',
          width: 100
        },
        activitySerial: {
          label: t('common.activitySerial'),
          value: 'activitySerial',
          align: 'center'
        },
        title: {
          label: t('common.activityName'),
          value: 'title',
          align: 'center'
        },
        platform: {
          label: t('common.activityGamePlatform'),
          value: 'platform',
          align: 'center'
        },
        multiple: {
          label: t('common.searchingEffectiveCash'),
          value: 'multiple',
          align: 'center'
        },
        cash: {
          label: t('common.activityCash'),
          value: 'cash',
          align: 'right'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        transferTo: {
          label: t('common.transferIn'),
          value: 'transferTo',
          align: 'center'
        },
        transferSerial: {
          label: t('common.serialNumber'),
          value: 'status',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        }
      },
      [
        'account',
        'createdAt',
        'activitySerial',
        'title',
        'platform',
        'multiple',
        'cash',
        'status',
        'transferTo',
        'transferSerial',
        'updatedAt'
      ],
      rows,
      'id'
    )
  }, [t, rows])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.activityWallet.encodePath
  })

  if (request === undefined) return null
  return (
    <React.Fragment>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              perPage={list.per_page}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </React.Fragment>
  )
}

export default React.memo(ActivityWalletTable)
