import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import ActivityWalletForm from '../../../components/admin/activity/wallet/ActivityWalletForm'
import ActivityWalletTable from '../../../components/admin/activity/wallet/ActivityWalletTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const ActivityWalletPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <ActivityWalletForm />
      </Box>
      <Box paddingTop={3}>
        <ActivityWalletTable />
      </Box>
    </Box>
  )
}

export default React.memo(ActivityWalletPage)
