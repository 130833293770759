import React, { useState, useMemo } from 'react'
import {
  ADMIN_API,
  AdminTransferTransactionStatusType,
  PaginationRes,
  TransferHistory,
  TransferHistorySum,
  PermissionType,
  WalletType
} from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './PlayerReportTransferForm'
import CoreTable from '../../../default/present/CoreTable'
import PointsCell from '../../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import TransferErrorStateActions from './TransferErrorStateActions'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload, usePerPageChange } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import allRoute from '../../route/route'
import transferTransactionStatusTypeName from '../../../../constants/admin/transferTransactionStatusTypeName'
import transferTransactionStatusTypeColor from '../../../../constants/admin/transferTransactionStatusTypeColor'
import DateTime from '../../../default/present/DateTime'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import PlayerReportTransferSingleAction from './PlayerReportTransferSingleAction'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { getWalletNameByWalletId } from '../../../../utils/default/PlatformHelper'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import { endOfDay, getTime, startOfDay } from 'date-fns'

interface RowType {
  id: number
  playerAccount: string
  staffAccount: string
  transactionAt: React.ReactElement
  finishedAt: React.ReactElement
  serial: React.ReactElement
  from: string
  cash: React.ReactElement
  to: string
  after: React.ReactElement
  status: React.ReactElement
  operator: string
}

interface SummaryRowType {
  id: number
  totalIn: React.ReactElement
  totalOut: React.ReactElement
  totalProcessing: React.ReactElement
  totalRollback: React.ReactElement
  totalFailure: React.ReactElement
  totalError: React.ReactElement
}

const PlayerReportTransferTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const platforms = useGDKStore.platform.platforms()
  const writableReview = useChecker([PermissionType.TRANSFER_RECORD_REVIEW])
  const { reload, reloadFlag } = useReload()
  const globalDialog = useGlobalDialog()
  const [list, setList] = useState<PaginationRes<TransferHistory[]> & TransferHistorySum>({
    ...createDefaultPaginationData([]),
    total_from: '',
    total_to: '',
    total_processing: '',
    total_rollback: '',
    total_failure: '',
    total_error: ''
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getTransferHistory(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<TransferHistory[]> & TransferHistorySum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])

  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      const showMessage = () => {
        gdk.history.getTransferHistoryException(item.id).subscribe(({ message }) => {
          globalDialog.setConfig(createGlobalDialogConfig({ message: message ?? t('common.none'), showCancel: false, okText: t('common.close'), showIcon: false }))
          globalDialog.setOpen({
            id: `transferException-${item.id}`,
            value: true,
            isOK: false
          })
        })
      }

      return {
        id: item.id,
        playerAccount: item.account,
        staffAccount: item.staff_account,
        transactionAt: (<DateTime time={item.created_at} />),
        finishedAt: item.finished_at ? (<DateTime time={item.finished_at} />) : '-',
        serial: item.activity_serial_number ? (<Link className={classes.anchor} to={allRoute.activityWallet.encodePath({ search: { search_type: 'created_at', start_at: getTime(startOfDay(item.created_at)), end_at: getTime(endOfDay(item.created_at)), serial_number: item.activity_serial_number, page: 1 }, param: {} })}>{item.transfer_number}</Link>) : (<div className={classes.anchor} onClick={showMessage}>{item.transfer_number}</div>),
        from: item.from === WalletType.ACTIVITY ? t('common.activityWallet') : getWalletNameByWalletId(item.from, platforms),
        cash: (<PointsCell points={item.cash} />),
        to: getWalletNameByWalletId(item.to, platforms),
        after: (<PointsCell points={item.after} />),
        status: (item.transaction_status === AdminTransferTransactionStatusType.ERROR && writableReview
          ? (<TransferErrorStateActions data={item} reload={reload} />)
          : (
            <span
              className={classes.chipText}
              style={{ backgroundColor: transferTransactionStatusTypeColor[item.transaction_status] }}
            >
              {t(transferTransactionStatusTypeName[item.transaction_status])}
            </span>
            )
        ),
        operator: item.updated_by
      } as RowType
    })
  }, [list.data, classes.anchor, classes.chipText, platforms, writableReview, reload, t, gdk.history, globalDialog])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        playerAccount: {
          label: t('common.playerAccount'),
          value: 'playerAccount',
          align: 'center'
        },
        staffAccount: {
          label: t('agent.staff'),
          value: 'staffAccount',
          align: 'center'
        },
        transactionAt: {
          label: t('common.transactionTime'),
          value: 'transactionAt',
          align: 'center',
          width: 100
        },
        finishedAt: {
          label: t('common.finishTime'),
          value: 'finishedAt',
          align: 'center'
        },
        serial: {
          label: t('common.transactionSerial'),
          value: 'serial',
          align: 'center'
        },
        from: {
          label: t('common.transferFrom2'),
          value: 'from',
          align: 'center'
        },
        cash: {
          label: t('common.transferMoney'),
          value: 'cash',
          align: 'right'
        },
        to: {
          label: t('common.transferTo2'),
          value: 'to',
          align: 'center'
        },
        after: {
          label: t('common.afterTransferMoney2'),
          value: 'after',
          align: 'right'
        },
        status: {
          label: t('common.transactionStatus'),
          value: 'status',
          align: 'center'
        },
        operator: {
          label: t('common.changeAccount'),
          value: 'operator',
          align: 'center'
        }
      },
      [
        'playerAccount',
        'staffAccount',
        'transactionAt',
        'serial',
        'from',
        'cash',
        'to',
        'after',
        'finishedAt',
        'status',
        'operator'
      ],
      rows,
      'id'
    )
  }, [t, rows])

  const summaryTableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const summaryRows: SummaryRowType[] = useMemo(() => ([{
    id: 1,
    totalIn: (<PointsCell points={list.total_from} />),
    totalOut: (<PointsCell points={list.total_to} />),
    totalProcessing: (<PointsCell points={list.total_processing} />),
    totalRollback: (<PointsCell points={list.total_rollback} />),
    totalFailure: (<PointsCell points={list.total_failure} />),
    totalError: (<PointsCell points={list.total_error} />)
  }]), [list])
  const summaryData = useMemo(() => {
    return createTableData<SummaryRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        totalIn: {
          label: t('common.totalTransferIn'),
          value: 'totalIn',
          align: 'right'
        },
        totalOut: {
          label: t('common.totalTransferOut'),
          value: 'totalOut',
          align: 'right'
        },
        totalProcessing: {
          label: t('common.processing'),
          value: 'totalProcessing',
          align: 'right'
        },
        totalRollback: {
          label: t('common.rollback'),
          value: 'totalRollback',
          align: 'right'
        },
        totalFailure: {
          label: t('common.failure'),
          value: 'totalFailure',
          align: 'right'
        },
        totalError: {
          label: t('common.abnormal'),
          value: 'totalError',
          align: 'right'
        }
      },
      [
        'totalIn',
        'totalOut',
        'totalProcessing',
        'totalRollback',
        'totalFailure',
        'totalError'
      ],
      summaryRows,
      'id'
    )
  }, [t, summaryRows])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.playerReportTransfer.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.playerReportTransfer.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_TRANSFER_HISTORY.url
  })

  if (request === undefined) return null
  return (
    <React.Fragment>
      <PlayerReportTransferSingleAction request={request} />
      <LoadingAndErrorFrame loading={false} showContent={pageFlow.status.showContent} error={null}>
        <Box paddingY={3}>
          <ScrollablePaper marginX={5}>
            <Box padding={4}>
              <CoreTable
                classes={summaryTableClasses}
                data={summaryData}
                total={1}
              />
            </Box>
          </ScrollablePaper>
        </Box>
      </LoadingAndErrorFrame>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              perPage={list.per_page}
              onChangePage={handlePagination}
              onChangeRowsPerPage={handlePerPageChange}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </React.Fragment>
  )
}

export default React.memo(PlayerReportTransferTable)
