import React, { useCallback } from 'react'
import {
  KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
  KeyboardDateTimePickerProps
} from '@golden/pickers'
import { MaterialUiPickersDate } from '@golden/pickers/typings/date'
import { useInternValue } from '../../../utils/default/FormHook'

export type PropTypes = {
  defaultValue?: Date | null
  value?: Date | null
  label?: string
  placeholder?: string
  helperText?: string
  error?: boolean
  required?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: MaterialUiPickersDate) => void
  shouldDisableDate?: (day: Date | null) => boolean
} & Omit<KeyboardDateTimePickerProps, 'onChange' | 'value'>

const DateTimePicker: React.FC<PropTypes> = (props) => {
  const {
    defaultValue,
    value,
    label,
    placeholder,
    helperText,
    error,
    required,
    onBlur,
    onFocus,
    onChange,
    FormHelperTextProps,
    inputProps,
    disabled,
    readOnly,
    shouldDisableDate
  } = props
  const [internValue, setInternValue] = useInternValue<Date | null>(
    defaultValue ?? value ?? null,
    value
  )
  return (
    <MuiKeyboardDateTimePicker
      FormHelperTextProps={FormHelperTextProps}
      autoOk
      fullWidth
      ampm={false}
      variant="inline"
      format="yyyy-MM-dd HH:mm:ss"
      mask="____-__-__ __:__:__"
      label={label}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      inputProps={{ ...inputProps, readOnly, placeholder }}
      value={internValue}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={useCallback((event) => {
        setInternValue(event ?? null)
        if (onChange) onChange(event)
      }, [onChange, setInternValue])}
      helperText={helperText}
      error={error}
      shouldDisableDate={shouldDisableDate}
    />
  )
}

export default React.memo(DateTimePicker)
