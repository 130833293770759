import React, { useState, useMemo, useEffect } from 'react'
import { AgentWalletsHistory, AgentWalletsSummary, PaginationRes, ErrorCode, AgentWalletTransactionType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import PointsCell from '../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import CoreTable from '../../default/present/CoreTable'
import { searchToRequest } from './AccountAgentWalletForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import {
  createDefaultPaginationData,
  createTableData,
  formatMoney
} from '../../../utils/default/TableHelper'
import allRoute from '../route/route'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../default/present/DateTime'
import agentAccountTypeName from '../../../constants/default/agentAccountTypeName'
import AgentTransactionText from '../../default/present/AgentTransactionText'
import agentWalletTransferTypeName from '../../../constants/default/agentWalletTransferTypeName'

const useStyles = makeStyles((theme) => ({
  invalidText: {
    color: theme.palette.error.main,
    marginTop: '15px'
  }
}))

interface SumRowType {
  id: number
  balance: string
  distribeAmount: string
  depositAmount: string
  rechargeAmount: string
  revokeAmount: string
}

interface RowType {
  id: number
  account: string
  accountType: string
  transactionType: React.ReactElement
  transferType: string
  transferNumber: string | React.ReactElement
  beforeAmount: string
  transferAmount: React.ReactElement
  afterAmount: string
  createdAt: React.ReactElement | string
  auditableAccount: string
  memo: React.ReactElement | string
  exchangedActivity: string
}

const AccountAgentWalletTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const reportPageFlow = usePageFlow()
  const listPageFlow = usePageFlow()
  const classes = useStyles()

  const [report, setReport] = useState<AgentWalletsSummary | null>(null)
  const [list, setList] = useState<PaginationRes<AgentWalletsHistory[]>>(
    createDefaultPaginationData([])
  )
  const [totalAmount, setTotalAmount] = useState('0')

  const request = useRequestFromSearch({ searchToRequest })

  useEffect(() => {
    setReport(null)
  }, [request])

  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.agentWallet.getSummary(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: reportPageFlow.setLoadingStart,
    onSuccess: (res: AgentWalletsSummary) => {
      setReport(res)
      reportPageFlow.setContentShow()
    },
    onError: (error) => {
      if (error.code !== ErrorCode.ACCOUNT_NOT_FOUND_OR_UNMATCHED_TYPE && error.code !== ErrorCode.ACCOUNT_EXISTS_AGENT_AND_USER) reportPageFlow.setGDKError(error)
    },
    canLoadData: request !== undefined && Object.keys(request).every(key => ['now_timestamp', 'page', 'account'].includes(key))
  })

  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.agentWallet.getHistories({
      ...payload,
      transaction_type: payload.transaction_type?.reduce<AgentWalletTransactionType[]>((accu, curr) => {
        if (curr === 'transfer' as unknown as AgentWalletTransactionType) {
          accu = accu.concat([AgentWalletTransactionType.IN, AgentWalletTransactionType.OUT])
        } else {
          accu.push(curr)
        }
        console.log(accu)
        return accu
      }, [])
    }),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: listPageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<AgentWalletsHistory[]> & { total_amount: string }) => {
      setList(res)
      setTotalAmount(res.total_amount)
      listPageFlow.setContentShow()
    },
    onError: listPageFlow.setGDKError,
    canLoadData: request !== undefined
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses])

  const sumRows: SumRowType[] = useMemo(() => {
    return [
      {
        id: 1,
        balance: formatMoney(report?.balance ?? 0),
        distribeAmount: formatMoney(report?.distribute_amount ?? 0),
        depositAmount: formatMoney(report?.deposit_amount ?? 0),
        rechargeAmount: formatMoney(report?.recharge_amount ?? 0),
        revokeAmount: formatMoney(report?.revoke_amount ?? 0)
      }
    ]
  }, [report])

  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        balance: {
          label: (
            <Box display="flex" justifyContent="flex-end">
              <span style={{ margin: 'auto' }}>{t('common.agentBalance')}</span>
              <span>+</span>
            </Box>
          ),
          value: 'balance',
          align: 'center'
        },
        distribeAmount: {
          label: t('common.distribeTotalAmount'),
          value: 'distribeAmount',
          align: 'center'
        },
        rechargeAmount: {
          label: (
            <Box display="flex" justifyContent="flex-end">
              <span style={{ margin: 'auto' }}>{t('common.rechargeTotalAmount')}</span>
              <span>—</span>
            </Box>
          ),
          value: 'rechargeAmount',
          align: 'center'
        },
        depositAmount: {
          label: (
            <Box display="flex" justifyContent="flex-end">
              <span style={{ margin: 'auto' }}>{t('common.agentDepositAmount')}</span>
              <span>=</span>
            </Box>
          ),
          value: 'depositAmount',
          align: 'center'
        },
        revokeAmount: {
          label: (
            <Box display="flex" justifyContent="flex-end">
              <span style={{ margin: 'auto' }}>{t('common.revokeTotalAmount')}</span>
              <span>+</span>
            </Box>
          ),
          value: 'revokeAmount',
          align: 'center'
        }
      },
      [
        'rechargeAmount',
        'revokeAmount',
        'depositAmount',
        'balance',
        'distribeAmount'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])

  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        account: item.account,
        accountType: t(agentAccountTypeName[item.account_type]),
        transactionType: (<AgentTransactionText type={item.transaction_type} />),
        transferNumber: item.transfer_number,
        transferType: item.transfer_type === null ? '-' : t(agentWalletTransferTypeName[item.transfer_type]),
        beforeAmount: formatMoney(item.before_amount),
        transferAmount: (<PointsCell showSign points={item.transfer_amount} />),
        afterAmount: formatMoney(item.after_amount),
        createdAt: (<DateTime time={item.created_at} />),
        auditableAccount: item.auditable_account,
        memo: item.memo,
        exchangedActivity: item.activity_title ?? '-',
        id: index
      } as RowType
    })
  }, [list, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center'
        },
        accountType: {
          label: t('common.accountType'),
          value: 'accountType',
          align: 'center'
        },
        transactionType: {
          label: t('common.transactionType'),
          value: 'transactionType',
          align: 'center'
        },
        transferType: {
          label: t('common.transferType'),
          value: 'transferType',
          align: 'center'
        },
        transferNumber: {
          label: t('common.serialNumber'),
          value: 'transferNumber',
          align: 'center'
        },
        beforeAmount: {
          label: t('common.beforeAmount'),
          value: 'beforeAmount',
          align: 'center'
        },
        transferAmount: {
          label: t('common.transferAmount'),
          value: 'transferAmount',
          align: 'center'
        },
        afterAmount: {
          label: t('common.afterAmount'),
          value: 'afterAmount',
          align: 'center'
        },
        createdAt: {
          label: t('common.updateAt'),
          value: 'createdAt',
          align: 'center'
        },
        auditableAccount: {
          label: t('common.updateBy'),
          value: 'auditableAccount',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        exchangedActivity: {
          label: t('common.exchangedActivity'),
          value: 'exchangedActivity',
          align: 'center'
        }
      },
      [
        'account',
        'accountType',
        'transactionType',
        'transferType',
        'transferNumber',
        'beforeAmount',
        'transferAmount',
        'afterAmount',
        'createdAt',
        'auditableAccount',
        'memo',
        'exchangedActivity'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.agentWalletHistory.encodePath
  })
  if (request === undefined) return null

  const isReportInvalid = report && BcMath.base(report.recharge_amount ?? 0).sub(report.revoke_amount ?? 0).add(report.deposit_amount ?? 0).get() !== BcMath.base(report.balance ?? 0).add(report.distribute_amount ?? 0).get()

  return (
    <React.Fragment>
      { report && <Box paddingTop={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame {...reportPageFlow.status}>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
              />
              {
                isReportInvalid && (<div className={classes.invalidText}>{t('common.formulaInvalid')}</div>)
              }
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      }
      <Box paddingTop={3}>
        <Box paddingX={4} marginBottom={3}>
          { `${t('common.totalTransactionAmount')}: ${Number(totalAmount) >= 0 ? '+' : ''}${formatMoney(totalAmount)}` }
        </Box>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame {...listPageFlow.status}>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={list.total}
                showPagination
                page={request?.page || 1}
                onChangePage={handlePagination}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(AccountAgentWalletTable)
