import React, { useContext, useMemo, useCallback, createContext } from 'react'
import { getTime, startOfDay, endOfDay, subYears, subMonths, subDays } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { FrequencyType, DeviceStatisticQuery } from '@golden/gdk-admin'
import { setLastMonth, createShouldDisableDate, setThisMonth } from '../../../utils/default/TimeHelper'
import FormStateProvider from '../../default/form/FormStateProvider'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getTimeFromDateInputValue, getValueFromChangeEvent, guaranteeNotUndefined, pipe, parseInt, guaranteeBeKey, guaranteeBetween } from '../../../utils/default/FormHelper'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { InitialFormFunc, useChangeUrlSubmit, SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../route/route'
import DropDown from '../../default/form/DropDown'
import frequencyName from '../../../constants/admin/frequencyName'

export interface DeviceStatisticFormType {
  period: FrequencyType
  time: DateInputValue
}

export const initialForm: InitialFormFunc<DeviceStatisticFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  period: FrequencyType.DAY,
  ...defaultForm
})

const formToRequest = (form: DeviceStatisticFormType): DeviceStatisticQuery => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    period: form.period
  }
}

const getChangedForm: ChangedFormGetter<DeviceStatisticFormType> = {
  period: (value, form) => ({ ...form, period: value, time: { start: startOfDay(new Date()), end: endOfDay(new Date()) } })
}

export const searchToRequest: SearchToRequestFunc<DeviceStatisticQuery> = (search) => {
  const converted = {
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt
    )(search.end_at),
    period: pipe(
      guaranteeNotUndefined,
      (value) => guaranteeBeKey(value, Object.keys(frequencyName)),
      parseInt
    )(search.period)
  } as DeviceStatisticQuery
  const fiveYearsAgo = subYears(startOfDay(new Date()), 5)
  const oneYearAgo = subYears(startOfDay(new Date()), 1)
  const threeMonthsAgo = subMonths(startOfDay(new Date()), 3)
  const today = endOfDay(new Date())
  let limit = threeMonthsAgo
  if (converted.period === FrequencyType.DAY) limit = threeMonthsAgo
  if (converted.period === FrequencyType.WEEK) limit = oneYearAgo
  if (converted.period === FrequencyType.MONTH) limit = fiveYearsAgo
  guaranteeBetween(converted.start_at, getTime(limit), getTime(today))
  guaranteeBetween(converted.end_at, getTime(limit), getTime(today))
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return converted
}

const getValueFromEvent: ValueGetter<DeviceStatisticFormType> = {
  period: getValueFromChangeEvent,
  time: getTimeFromDateInputValue
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const FrequencyInput: React.FC = React.memo(() => {
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const options = useMemo(() => {
    return Object.keys(frequencyName)
      .map((key) => Number(key) as FrequencyType)
      .map((key) => ({ name: t(frequencyName[key]), value: key }))
  }, [t])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('period'), [])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        label={t('common.frequency')}
        options={options}
        value={value.period}
        onChange={onChange}
        fullWidth
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const fiveYearsAgo = useMemo(() => subYears(startOfDay(new Date()), 5), [])
  const oneYearAgo = useMemo(() => subYears(startOfDay(new Date()), 1), [])
  const threeMonthsAgo = useMemo(() => subMonths(startOfDay(new Date()), 3), [])
  const yesterday = subDays(endOfDay(new Date()), 1)
  const limit = useMemo(() => {
    let limit = threeMonthsAgo
    if (value.period === FrequencyType.DAY) limit = threeMonthsAgo
    if (value.period === FrequencyType.WEEK) limit = oneYearAgo
    if (value.period === FrequencyType.MONTH) limit = fiveYearsAgo
    return limit
  }, [value.period, fiveYearsAgo, oneYearAgo, threeMonthsAgo])
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    return createShouldDisableDate(value.time.start, value.time.end, limit, yesterday)
  }, [limit, value.time, yesterday])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={FrequencyInput}
      denseToolbar
      onlyDate
    />
  )
})

interface PropTypes {
  request: DeviceStatisticQuery | undefined
}

const AdminReportDeviceStatisticForm: React.FC<PropTypes> = (props) => {
  const { request } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.adminReportDeviceStatistic.encodePath
  })
  const validation = useMemo(() => {
    return {
      time: [],
      period: []
    } as FormValidation<DeviceStatisticFormType>
  }, [])
  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        period: request.period
      })
    }
    return initialForm()
  }, [request])
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.deviceStatistic')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Box whiteSpace="pre">
                <Typography color="error">
                  {t('common.deviceStatisticTip')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(AdminReportDeviceStatisticForm)
