import React, { useMemo, createContext, useContext, useCallback } from 'react'
import {
  PaginationReq,
  AgentOperationLogQuery,
  AgentType,
  AgentOperationLogsCategoryType,
  AgentOperationLogsAnnouncementMethod,
  AgentOperationLogsAssistantMethod,
  AgentOperationLogsLayerMethod,
  AgentOperationLogsPlayerAccountMethod,
  AgentOperationLogsSettingMethod,
  AgentOperationLogsWalletMethod,
  AgentOperationLogsMemoMethod,
  NowTime
} from '@golden/gdk-admin'
import { createValidateStartAtWithEndAt, createValidateNotEmpty } from '../../../utils/default/Validator'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, pipe, parseInt, acceptUndefined, convertEmptyToUndefined, getTimeFromDateInputValue, getValueFromChangeEvent, getValueFromCheckboxEvent } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { FormValidation, createDefaultFormState, ValueGetter, ChangedFormGetter } from '../../../utils/default/FormHook'
import { setToday, setYesterday, setThisMonth, setLastMonth } from '../../../utils/default/TimeHelper'
import agentTypeName from '../../../constants/default/agentTypeName'
import { startOfDay, endOfDay, getTime, isAfter, isBefore, subSeconds, addMonths, subMonths, addDays } from 'date-fns'
import { isUndefined, omitBy } from '@golden/utils'

export type AgentOpertationLogRequest = PaginationReq & AgentOperationLogQuery

export interface AccountAgentOperationLogFormType {
  time: DateInputValue
  agent_account: string
  is_chain: boolean
  category: AgentOperationLogsCategoryType | '--'
  method: string
  agent_type: AgentType | '--'
  operator: string
}

export const searchToRequest: SearchToRequestFunc<AgentOpertationLogRequest> = (search) => {
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt
    )),
    agent_type: acceptUndefined(search.agent_type, pipe()),
    is_chain: acceptUndefined(search.is_chain, parseInt)
  } as AgentOpertationLogRequest
  return omitBy(converted, isUndefined) as AgentOpertationLogRequest
}

export const initialForm: InitialFormFunc<AccountAgentOperationLogFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(addDays(subMonths(NowTime.get(), 3), 1)),
    end: endOfDay(NowTime.get())
  },
  agent_account: '',
  agent_type: '--',
  is_chain: false,
  category: '--',
  method: '--',
  operator: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<AccountAgentOperationLogFormType> = {
  time: getTimeFromDateInputValue,
  agent_account: getValueFromChangeEvent,
  agent_type: getValueFromChangeEvent,
  is_chain: getValueFromCheckboxEvent,
  category: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  operator: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<AccountAgentOperationLogFormType> = {
  time: (value, form) => {
    if (value.start && value.end && (isAfter(value.end, subSeconds(addMonths(value.start, 3), 1)) || isBefore(value.end, value.start))) {
      return { ...form, time: { start: value.start, end: subSeconds(addMonths(value.start, 3), 1) } }
    }
    return { ...form, time: value }
  },
  category: (value, form) => ({
    ...form,
    category: value,
    method: '--'
  })
}

const formToRequest = (form: AccountAgentOperationLogFormType): AgentOpertationLogRequest => {
  const converted = {
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    agent_account: convertEmptyToUndefined(form.agent_account),
    agent_type: form.agent_type === '--' ? undefined : form.agent_type,
    category: form.category === '--' ? undefined : form.category,
    method: form.method === '--' ? undefined : form.method,
    operator: convertEmptyToUndefined(form.operator),
    is_chain: form.is_chain ? 1 : undefined,
    page: 1
  } as AgentOpertationLogRequest
  return omitBy(converted, isUndefined) as AgentOpertationLogRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const threeMonthsAgoFromStartTime = value.time.start ? subSeconds(addMonths(value.time.start, 3), 1) : new Date()
    const shouldDisableStartDate = (): boolean => {
      return false
    }
    const shouldDisableEndDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (isAfter(day, endOfDay(threeMonthsAgoFromStartTime))) return true
      if (value.time.start === null) return false
      return isBefore(day, value.time.start)
    }

    return {
      shouldDisableStartDate,
      shouldDisableEndDate
    }
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const MethodsDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)

  const methodOptions = useMemo(() => {
    const all = [
      {
        name: t('common.all'),
        value: '--'
      }
    ]

    let options: Array<{ name: string, value: string }> = []

    switch (value.category) {
      case AgentOperationLogsCategoryType.SETTING:
        options = [
          {
            name: t('common.loginPassword'),
            value: AgentOperationLogsSettingMethod.LOGIN_PASSWORD
          },
          {
            name: t('common.securityPassword'),
            value: AgentOperationLogsSettingMethod.SECURITY_PASSWORD
          }
        ]
        break
      case AgentOperationLogsCategoryType.LAYER:
        options = [
          {
            name: t('common.createNewLayer'),
            value: AgentOperationLogsLayerMethod.NEW_LAYER
          },
          {
            name: t('common.isLoginable'),
            value: AgentOperationLogsLayerMethod.ALLOW_LOGIN
          },
          {
            name: t('common.nickname'),
            value: AgentOperationLogsLayerMethod.NICK_NAME
          },
          {
            name: t('common.loginPassword'),
            value: AgentOperationLogsLayerMethod.LOGIN_PASSWORD
          },
          {
            name: t('common.splitPercentage'),
            value: AgentOperationLogsLayerMethod.PERCENTAGE
          },
          {
            name: t('common.securityPassword'),
            value: AgentOperationLogsSettingMethod.SECURITY_PASSWORD
          }
        ]
        break
      case AgentOperationLogsCategoryType.WALLET:
        options = [
          {
            name: t('common.deposit2'),
            value: AgentOperationLogsWalletMethod.DEPOSIT
          },
          {
            name: t('common.agentWalletTransfer'),
            value: AgentOperationLogsWalletMethod.TRANSFER
          },
          {
            name: t('common.staffDeposit'),
            value: AgentOperationLogsWalletMethod.AGENT_DEPOSIT
          }
        ]
        break
      case AgentOperationLogsCategoryType.PLAYER_ACCOUNT:
        options = [
          {
            name: t('common.bubbleId'),
            value: AgentOperationLogsPlayerAccountMethod.BUBBLE_ID
          }
        ]
        break
      case AgentOperationLogsCategoryType.ASSISTANT:
        options = [
          {
            name: t('common.createAccount'),
            value: AgentOperationLogsAssistantMethod.NEW_ASSISTANT
          },
          {
            name: t('common.nickname'),
            value: AgentOperationLogsAssistantMethod.NICK_NAME
          },
          {
            name: t('common.assistantType'),
            value: AgentOperationLogsAssistantMethod.TYPE
          },
          {
            name: t('common.isLoginable'),
            value: AgentOperationLogsAssistantMethod.ALLOW_LOGIN
          },
          {
            name: t('common.loginPassword'),
            value: AgentOperationLogsAssistantMethod.LOGIN_PASSWORD
          },
          {
            name: t('common.customPermission'),
            value: AgentOperationLogsAssistantMethod.CUSTOM_PERMISSION
          }
        ]
        break
      case AgentOperationLogsCategoryType.ANNOUNCEMENT:
        options = [
          {
            name: t('common.staffAnnouncement'),
            value: AgentOperationLogsAnnouncementMethod.STAFF_ANNOUNCEMENT
          }
        ]
        break
      case AgentOperationLogsCategoryType.MEMO:
        options = [
          {
            name: t('common.memo'),
            value: AgentOperationLogsMemoMethod.MEMO
          }
        ]
        break
    }

    return all.concat(options)
  }, [t, value.category])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('method'), [])
  if (value.category === '--') {
    return (
      <Grid item xs={12} md={3} />
    )
  }

  return (
    <Grid item xs={12} md={3}>
      <DropDown
        label={t('common.function')}
        options={methodOptions}
        value={value.method}
        onChange={onChange}
        fullWidth
      />
    </Grid>
  )
})

const AccountOperationLogForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })
  const defaultValue = useMemo(() => {
    if (request) {
      return initialForm({
        ...(request as unknown as AccountAgentOperationLogFormType),
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        agent_type: request?.agent_type ?? '--'
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.accountAgentOperationLog.encodePath
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    agent_account: [],
    agent_type: [],
    category: [
      {
        func: createValidateNotEmpty('category', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    method: [],
    operator: [],
    is_chain: []
  } as FormValidation<AccountAgentOperationLogFormType>), [t])

  const accountTypeOptions = useMemo(() => [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(agentTypeName).map((key) => ({ name: t(agentTypeName[Number(key) as AgentType]), value: key }))
  ), [t])

  const categoryOptions = useMemo(() => [
    {
      name: t('common.all'),
      value: '--'
    },
    {
      name: t('common.setting'),
      value: AgentOperationLogsCategoryType.SETTING
    },
    {
      name: t('common.manageLayer'),
      value: AgentOperationLogsCategoryType.LAYER
    },
    {
      name: t('common.wallet'),
      value: AgentOperationLogsCategoryType.WALLET
    },
    {
      name: t('common.player'),
      value: AgentOperationLogsCategoryType.PLAYER_ACCOUNT
    },
    {
      name: t('common.assistant'),
      value: AgentOperationLogsCategoryType.ASSISTANT
    },
    {
      name: t('common.announcement'),
      value: AgentOperationLogsCategoryType.ANNOUNCEMENT
    },
    {
      name: t('common.memo'),
      value: AgentOperationLogsCategoryType.MEMO
    }
  ], [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.agentOperationLog')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentOperationLogFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="agent_account"
                      label={t('common.agentAccount')}
                      placeholder={t('placeholder.inputAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentOperationLogFormType, OnOffCheckboxProps>
                      context={FormContext}
                      component={OnOffCheckbox}
                      name="is_chain"
                      label={t('common.checkAgentLine')}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentOperationLogFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="category"
                      label={t('common.block')}
                      options={categoryOptions}
                      fullWidth
                    />
                  </Grid>
                  <MethodsDropDown />
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentOperationLogFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="agent_type"
                      label={t('common.accountType')}
                      options={accountTypeOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentOperationLogFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="operator"
                      label={t('common.updateBy')}
                      placeholder={t('placeholder.inputUpdateBy')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(AccountOperationLogForm)
