import React, { useState, useMemo, useCallback } from 'react'
import { ActivityApplicationStatusType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { useGDKFuncHandleClick } from '../../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import DropDown from '../../../../components/default/form/DropDown'
import RadioGroup from '../../../../components/default/form/RadioGroup'
import MuiTextField from '@material-ui/core/TextField'

interface PropTypes {
  id?: number
  ids?: Array<{ id: number, batchId: string }>
  playerAccount?: string
  tab: ActivityApplicationStatusType
  reload: () => void
  isBatch?: boolean
  setSelected: (value: { [key in ActivityApplicationStatusType]: Array<{ id: number, batchId: string }> }) => void
}

const TextField = React.memo(MuiTextField)

const typeMapText = {
  passed: 'common.passed',
  unpassed: 'common.unpassed',
  update: 'common.update'
} as const

const OperationButton: React.FC<PropTypes & {
  type: 'passed' | 'unpassed' | 'update'
}> = React.memo((props) => {
  const { type, id = 0, ids = [], playerAccount = '', reload, isBatch = false, setSelected } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()

  const [open, setOpen] = useState(false)
  const [dropdownValue, setDropdownValue] = useState<string | -1 | -2>(t('common.notReachStandard'))
  const [inputValue, setInputValue] = useState('')
  const [radioValue, setRadioValue] = useState(ActivityApplicationStatusType.PASSED)

  const showDropDown = useMemo(() => (type === 'update' && radioValue === ActivityApplicationStatusType.REJECTED) || type === 'unpassed', [radioValue, type])
  const showInput = useMemo(() => dropdownValue === -1 || type === 'passed' || (type === 'update' && radioValue === ActivityApplicationStatusType.PASSED), [dropdownValue, radioValue, type])
  const showRadio = useMemo(() => type === 'update', [type])

  const typeMapButtonClass = {
    passed: commonClasses.greenGradualOutlineButton,
    unpassed: commonClasses.pinkGradualOutlineButton,
    update: commonClasses.greyOutlineButton
  }

  const statusOptions = useMemo(() => [
    { value: ActivityApplicationStatusType.PASSED, name: t('common.passed') },
    { value: ActivityApplicationStatusType.REJECTED, name: t('common.unpassed') }
  ], [t])

  const memoOptions = useMemo(() => [
    ...[t('common.notReachStandard'), t('common.illegalBet'), t('common.positiveProfit')].map((value) => ({ value, name: value })),
    { value: -1, name: t('common.selfInput') }
  ], [t])

  const handleClose = useCallback(() => {
    setOpen(false)
    setDropdownValue(t('common.notReachStandard'))
    setInputValue('')
    setRadioValue(ActivityApplicationStatusType.PASSED)
  }, [t])

  const payload = useMemo(() => {
    return ({
      ids: isBatch ? ids.map((el) => el.id) : [id],
      status: type === 'update'
        ? radioValue
        : type === 'passed'
          ? ActivityApplicationStatusType.PASSED
          : ActivityApplicationStatusType.REJECTED,
      memo: dropdownValue === -1 || type === 'passed' || (type === 'update' && radioValue === ActivityApplicationStatusType.PASSED)
        ? inputValue
        : dropdownValue === -2
          ? ''
          : dropdownValue
    })
  }, [dropdownValue, id, ids, inputValue, isBatch, radioValue, type])

  const { handleClick, loading } = useGDKFuncHandleClick({
    payload,
    gdkFunc: (payload) => gdk.activity.updateActivityApplication(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      globalDialog.setConfig(createGlobalDialogConfig({
        message: t('dialog.updateSuccess'),
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `updateSuccess-${payload.ids.join('-')}`,
        value: true,
        isOK: false
      })
      reload()
      setSelected({ created: [], passed: [], rejected: [] })
      handleClose()
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        message: error.message,
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `updateFailed-${id}`,
        value: true,
        isOK: false
      })
    }
  })

  const dialogTitle = useMemo(() => isBatch
    ? t('dialog.confirmBatchUpdateActivityApplication', { action: t(typeMapText[type]), amount: ids.length })
    : t('dialog.confirmUpdateActivityApplication', { action: t(typeMapText[type]), playerAccount }), [ids.length, isBatch, playerAccount, t, type])

  const tip = useMemo(() => {
    const batchIds = [...new Set(ids.map((el) => el.batchId))]
    if (batchIds.length <= 1) return ''
    return `${t('common.activityApplicationBatchIdTip')}${batchIds.join('、')}`
  }, [ids, t])

  return (<>
    <Box margin={1}>
      <Button
        onClick={() => setOpen(true)}
        className={typeMapButtonClass[type]}
      >
        {isBatch ? t('common.batchSomething', { item: t(typeMapText[type]) }) : t(typeMapText[type])}
      </Button>
    </Box>
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle className={commonClasses.dialogPinkHeader}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            { !!tip && (<Typography color="error" align="center">{tip}</Typography>) }
          </Grid>
          { showRadio && <Grid item>
            <RadioGroup
              label={t('userStatus.reviewing')}
              value={radioValue}
              options={statusOptions}
              onChange={(event) => {
                const value = event.target.value as ActivityApplicationStatusType
                setRadioValue(value)
                setInputValue('')
              }}
            />
          </Grid> }
          { showDropDown && <Grid item>
            <DropDown
              autoFocus
              fullWidth
              label={t('common.memo')}
              value={dropdownValue}
              options={memoOptions}
              onChange={(event) => {
                const value = event.target.value as string | -1
                setDropdownValue(value)
              }}
            />
          </Grid> }
          { showInput && <Grid item>
            <TextField
              autoFocus
              fullWidth
              type="text"
              margin="normal"
              variant="outlined"
              label={!showDropDown ? t('common.memo') : ''}
              placeholder={t('placeholder.pleaseInput', { item: t('common.memo') })}
              FormHelperTextProps={{ error: true }}
              defaultValue={inputValue}
              onChange={(event) => {
                const value = event.target.value
                setInputValue(value)
              }}
            />
          </Grid> }
        </Grid>
      </DialogContent>
      <Box padding={2}>
        <DialogActions>
          <Grid container spacing={1} justify="center">
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleClick}
                disabled={loading}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  </>)
})

const ActivityApplicationOperation: React.FC<PropTypes> = (props) => {
  const { tab } = props

  if (tab === ActivityApplicationStatusType.CREATED) {
    return (<>
      <OperationButton
        type="passed"
        {...props}
      />
      <OperationButton
        type="unpassed"
        {...props}
      />
    </>)
  }
  return (
    <OperationButton
      type="update"
      {...props}
    />
  )
}

export default React.memo(ActivityApplicationOperation)
