import React, { createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import ComplainForm from '../../../components/admin/complain/ComplainForm'
import ComplainTable from '../../../components/admin/complain/ComplainTable'
import ComplainDialog, { Payload as ComplainDialogPayload } from '../../../components/admin/complain/ComplainDialog'
import StateProvider from '../../../providers/default/StateProvider'

const defaultPayload: ComplainDialogPayload = {
  showTextField: false,
  showCancel: false,
  okText: '',
  onOK: () => {},
  onCancel: () => {},
  onChange: () => {},
  open: false,
  form: {
    title: '',
    complain: '',
    reply: '',
    fromAccount: '',
    toAccount: ''
  }
}

export const DialogPayloadContext = createContext<[
  ComplainDialogPayload,
  Dispatch<SetStateAction<ComplainDialogPayload>>
]>([
  defaultPayload,
  () => {}
])

const ComplainPage: React.FC = () => {
  return (
    <StateProvider
      defaultValue={defaultPayload}
      context={DialogPayloadContext}
    >
      <Box paddingY={5}>
        <Box paddingX={5}>
          <ComplainForm />
        </Box>
        <Box paddingTop={3}>
          <ComplainTable />
        </Box>
      </Box>
      <ComplainDialog />
    </StateProvider>
  )
}

export default React.memo(ComplainPage)
