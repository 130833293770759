import React, { useEffect, useMemo, useState } from 'react'
import useGDK from './gdk/useGDK'
import tipAudio from '../../assets/admin/audios/complain-alert.mp3'
import useGDKStore from './gdk/useGDKStore'
import { PermissionType, Branch } from '@golden/gdk-admin'
import { getTime } from 'date-fns'

const TipAudio = new Audio(tipAudio)

const ComplainAlertHandler: React.FC = () => {
  const gdk = useGDK()
  const isLogined = useGDKStore.auth.isLoggedIn()
  const me = useGDKStore.admin.me()
  const [, setCaches] = useState<{
    complains: number[]
  }>({
    complains: []
  })

  useEffect(() => {
    gdk.trunk.next([{ branch: Branch.ADMIN_FINANCE_ALERT, openedAt: getTime(new Date()) }])
  }, [isLogined, gdk])

  const hasPermission = useMemo(() => {
    return me &&
      !me?.is_need_authenticator &&
      !me?.is_need_update_password &&
      (me.permissions.includes(PermissionType.COMPLAIN) || me.permissions.includes(PermissionType.COMPLAIN_READ))
  }, [me])

  useEffect(() => {
    if (hasPermission) {
      const subscription = gdk.complain.alert.subscribe({
        next: (res) => {
          setCaches((caches) => {
            const complains = res.complain_ids.filter((id) => {
              if (caches.complains.includes(id)) return false
              TipAudio.play()?.catch(() => {})
              return true
            })
            return {
              complains: caches.complains.concat(complains)
            }
          })
        }
      })
      return () => subscription.unsubscribe()
    }
  }, [gdk, hasPermission])
  return null
}

export default React.memo(ComplainAlertHandler)
