import React, { useMemo, createContext, useContext, useCallback } from 'react'
import { PaginationReq, EffectiveCashHistoryQuery, WithdrawPointReason, WithdrawPointTarget, GameType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { GAME_ORDER } from '@golden/game-china'
import { startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import GameIdInput, { PropTypes as GameIdProps } from '../GameIdInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, getValueFromChangeEvent, convertEmptyToUndefined, getTimeFromDateInputValue, acceptUndefined, getValueFromValue } from '../../../utils/default/FormHelper'
import allRoutes, { Path } from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import RequiredText from '../../default/form/RequiredText'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth } from '../../../utils/default/TimeHelper'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { useLocation } from 'react-router'
import { parsePath } from '../../../utils/default/RouteHelper'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import withdrawPointReasonName from '../../../constants/admin/withdrawPointReasonName'
import withdrawPointTargetName from '../../../constants/admin/withdrawPointTargetName'

export type EffectiveCashHistoryRequest = PaginationReq & EffectiveCashHistoryQuery

export interface EffectiveCashHistoryFormType {
  time: DateInputValue
  account: string
  target: WithdrawPointTarget | '--'
  target_id: GameType
  reason: WithdrawPointReason | '--'
  creator_account: string
}

export const searchToRequest: SearchToRequestFunc<EffectiveCashHistoryRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    is_reset: acceptUndefined(search.is_reset, pipe(
      parseInt
    )),
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    ))
  } as EffectiveCashHistoryRequest
  return omitBy(converted, isUndefined) as EffectiveCashHistoryRequest
}

export const initialForm: InitialFormFunc<EffectiveCashHistoryFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  creator_account: '',
  target: '--',
  target_id: GAME_ORDER[0],
  reason: '--',
  account: '',
  ...defaultForm
})

const formToRequest = (form: EffectiveCashHistoryFormType): EffectiveCashHistoryRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: form.account,
    target: form.target !== '--' ? form.target : undefined,
    target_id: form.target === WithdrawPointTarget.GAME ? form.target_id : undefined,
    reason: (form.reason !== '--' && form.reason !== WithdrawPointReason.RESET) ? form.reason : undefined,
    is_reset: form.reason === WithdrawPointReason.RESET ? 1 : undefined, // 原因不是重置就不要帶，因為要搜索全部
    creator_account: convertEmptyToUndefined(form.creator_account),
    page: 1
  } as EffectiveCashHistoryRequest
  return omitBy(converted, isUndefined) as EffectiveCashHistoryRequest
}

const getValueFromEvent: ValueGetter<EffectiveCashHistoryFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  target: getValueFromChangeEvent,
  target_id: getValueFromValue,
  reason: getValueFromChangeEvent,
  creator_account: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      onlyDate
    />
  )
})

const GameInput: React.FC = React.memo(() => {
  const { t } = useT()
  const { value } = useContext(FormContext)

  if (value.target === WithdrawPointTarget.GAME) {
    return (
      <Grid item xs={12} md={6} lg={3}>
        <FormField<EffectiveCashHistoryFormType, GameIdProps>
          context={FormContext}
          component={GameIdInput}
          name="target_id"
          label={t('common.gamePlatform')}
          fullWidth
          noAll
        />
      </Grid>
    )
  }
  return null
})

const EffectiveCashHistoryForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const request = useRequestFromSearch({ searchToRequest })

  const location = useLocation()
  const account = useMemo(() => {
    const { param } = parsePath(location.search, location.pathname, Path.MANUAL_EFFECTIVE_CASH_HISTORY)
    return param.account
  }, [location.search, location.pathname])

  const defaultForm = useMemo(() => {
    return initialForm({
      ...request,
      time: {
        start: request?.start_at ? new Date(request.start_at) : startOfDay(new Date()),
        end: request?.end_at ? new Date(request.end_at) : endOfDay(new Date())
      },
      reason: request?.is_reset === 1 ? WithdrawPointReason.RESET : request?.reason ?? '--'
    })
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    param: { account },
    encodePath: allRoutes.manualEffectiveCashHistory.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    account: [
      {
        func: createValidateNotEmpty('account', t),
        when: ['beforeClickSubmit']
      }
    ],
    target: [],
    target_id: [],
    reason: [],
    creator_account: []
  } as FormValidation<EffectiveCashHistoryFormType>), [t])

  const targetOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }].concat(
      Object.keys(withdrawPointTargetName)
        .map((key) => ({ name: t(withdrawPointTargetName[key as WithdrawPointTarget]), value: key }))
    )
  }, [t])

  const reasonOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }].concat(
      Object.keys(withdrawPointReasonName)
        .map((key) => ({ name: t(withdrawPointReasonName[key as WithdrawPointReason]), value: key }))
    )
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.effectiveCashHistory')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<EffectiveCashHistoryFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<EffectiveCashHistoryFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="target"
                    label={t('common.effectiveCashType2')}
                    options={targetOptions}
                    fullWidth
                  />
                </Grid>
                <GameInput />
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<EffectiveCashHistoryFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="reason"
                    label={t('common.reason')}
                    options={reasonOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<EffectiveCashHistoryFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="creator_account"
                    label={t('common.updateBy')}
                    placeholder={t('placeholder.inputUpdateBy')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(EffectiveCashHistoryForm)
